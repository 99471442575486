import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import CondominiumInviteTab from "./CondominiumInviteTab";
import { useSelector, useDispatch } from "react-redux";
import BlockGroups from "./BlockGroups";
import { setCondomiumTabValue } from "../../redux/slices/condominiumSlice";
import ApproveTab from "./ApproveTab";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { hasCommonValue } from "../../utils/api_params";
import BalanceIndex from "../../pages/balances/BalanceIndex";
import BlockStats from "./BlockStats";
import { useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import { setBlockTabName } from "../../redux/slices/condominiumSlice";
import AssoUserToApartment from "../metrics/AssoUserToApartment";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CondominiumTabs = ({ mainTab, readings, consume, meter }) => {
  const dispatch = useDispatch();
  const permessions = window.COMPONENTS;
  const location = useLocation();
  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const [value, setValue] = React.useState(0);
  const { t } = useTranslation();
  const condominiumTabValue = useSelector(
    (state) => state?.condominiumSlice?.condominiumTabValue
  );
  const blockTabName = useSelector(
    (state) => state?.condominiumSlice?.blockTabName
  );
  const handleChange = (event, newValue) => {
    dispatch(setCondomiumTabValue(newValue));
  };
  const btnNameDetector = useSelector(
    (state) => state.condominiumSlice.btnDetector
  );

  React.useEffect(() => {
    if (location.pathname.includes("balance")) {
      dispatch(setCondomiumTabValue(6));
    }
  }, []);

  const tabs = [
    {
      visibility:
        permessions?.["block_stats"]?.visibility &&
        hasCommonValue(roles, permessions["block_stats"]?.roles) &&
        btnNameDetector == 1,
      tab: <Tab label={t("stats")} />,
      tabPan: <BlockStats />,
    },
    {
      visibility:
        permessions?.["block_info"]?.visibility &&
        hasCommonValue(roles, permessions["block_info"]?.roles),
      tab: <Tab label={t("info")} />,
      tabPan: mainTab,
    },
    {
      visibility:
        permessions?.["block_readings"]?.visibility &&
        hasCommonValue(roles, permessions["block_readings"]?.roles),
      tab: <Tab label={t("radings_tab")} />,
      tabPan: readings,
    },
    {
      visibility:
        permessions?.["block_consumptions"]?.visibility &&
        hasCommonValue(roles, permessions["block_consumptions"]?.roles) &&
        btnNameDetector == 1,
      tab: <Tab label={t("consume_tab")} />,
      tabPan: consume,
    },
    {
      visibility:
        permessions?.["block_meter"]?.visibility &&
        hasCommonValue(roles, permessions["block_meter"]?.roles),
      tab: <Tab label={t("meter_tab")} disabled />,
      tabPan: meter,
    },
    {
      visibility:
        permessions?.["block_invite"]?.visibility &&
        hasCommonValue(roles, permessions["block_invite"]?.roles),
      tab: <Tab label={t("invites")} />,
      tabPan: <CondominiumInviteTab />,
    },
    {
      visibility:
        permessions?.["block_usergroups"]?.visibility &&
        hasCommonValue(roles, permessions["block_usergroups"]?.roles),
      tab: <Tab label={t("user_groups")} />,
      tabPan: <BlockGroups />,
    },
    {
      visibility:
        permessions?.["block_balance"]?.visibility &&
        hasCommonValue(roles, permessions["block_balance"]?.roles) &&
        btnNameDetector == 1,
      tab: <Tab label={t("balances")} />,
      tabPan: <BalanceIndex />,
    },
    {
      visibility:
        permessions?.["block_approve"]?.visibility &&
        hasCommonValue(roles, permessions["block_approve"]?.roles),

      tab: <Tab label={t("block_approve")} />,
      tabPan: <ApproveTab />,
      name: "block_approve",
    },
    {
      visibility:
        permessions?.["meter_assoc"]?.visibility &&
        hasCommonValue(roles, permessions["meter_assoc"]?.roles) &&
        btnNameDetector == 3,

      tab: <Tab label={t("assoc_end_user")} />,
      tabPan: <AssoUserToApartment />,
      name: "block_approve",
    },
  ];
  const filteredTabs = tabs?.filter((item) => item?.visibility == true);
  React.useEffect(() => {}, [filteredTabs]);
  return (
    <Box sx={{ width: "100%", ml: 1 }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={condominiumTabValue} onChange={handleChange}>
          {filteredTabs?.map((item) => item?.tab)}
        </Tabs>
      </Box>

      {filteredTabs?.map((item, i) => (
        <TabPanel index={i} value={condominiumTabValue}>
          {item?.tabPan}
        </TabPanel>
      ))}
    </Box>
  );
};
export default CondominiumTabs;
