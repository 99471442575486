import { List, ListItem, Paper, Tooltip, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import EditIcon from "@mui/icons-material/Edit";
import { pink } from "@mui/material/colors";
import { useTranslation } from "react-i18next";
import { center } from "@turf/turf";

const MandatoryIcon = ({ mandatory }) => {
  const { t } = useTranslation();
  if (mandatory) {
    return (
      <Tooltip title={t("mandatory")}>
        <StarRateIcon color="warning" />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={t("optional")}>
        <EditIcon color="warning" />
      </Tooltip>
    );
  }

  return <></>;
};

const PolicyGuideMapping = () => {
  const mappingConfigurations = useSelector(
    (state) => state?.importSlice?.mappingConfiguration
  );
  const { t } = useTranslation();

  return (
    <div style={{ marginTop: "30px" }}>
      <Typography variant="h5" gutterBottom>
        {t("import_column_list")}
      </Typography>
      <List>
        <ListItem secondaryAction={<MandatoryIcon mandatory={true} />}>
          label
        </ListItem>
        {mappingConfigurations?.map(
          ({ attributes: { in: inValue, mandatory } }) => (
            <ListItem secondaryAction={<MandatoryIcon mandatory={mandatory} />}>
              {inValue}
            </ListItem>
          )
        )}
      </List>

      <Typography variant="h5" gutterBottom>
        {t("csv_example")}
      </Typography>
      <Paper
        elevation={3}
        sx={{ padding: 2, textAlign: "center", background: "lightgray" }}
      >
        <pre>
          label;
          {mappingConfigurations
            ?.map(({ attributes: { in: inValue } }) => inValue)
            .join(";")}
        </pre>
      </Paper>
    </div>
  );
};

export default PolicyGuideMapping;
