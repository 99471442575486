import React, { useEffect, useState } from "react";
import { useMeasure } from "react-use";
import { Box } from "@material-ui/core";
import {
  VictoryChart,
  VictoryAxis,
  VictoryLine,
  VictoryZoomContainer,
  VictoryGroup,
  VictoryTooltip,
  VictoryTheme,
  VictoryScatter,
  createContainer,
} from "victory";
import { useTranslation } from "react-i18next";

const ZoomVoronoiContainer = createContainer("zoom", "voronoi");

export default function ColorMeterConsumGraph({ data }) {
  const { t } = useTranslation();
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [ref, { width }] = useMeasure();

  const getMinMaxValues = (data, key) => ({
    min: Math.min(...data.map((item) => item[key])),
    max: Math.max(...data.map((item) => item[key])),
  });

  const { min: minCalories, max: maxCalories } = getMinMaxValues(
    data,
    "calories"
  );
  const { min: minFrigories, max: maxFrigories } = getMinMaxValues(
    data,
    "frigories"
  );
  const scaleFactorFrigories =
    maxFrigories === 0 ? 1 : maxCalories / maxFrigories;

  const caloriesData = data.map((item, i) => ({
    x: t("date_val", { val: new Date(item.date) }),
    y: item.calories,
    calories: item.calories,
    frigories: item.frigories,
    index: i,
  }));

  const frigoriesData = data.map((item, i) => ({
    x: t("date_val", { val: new Date(item.date) }),
    y: item.frigories * scaleFactorFrigories,
    calories: item.calories,
    frigories: item.frigories,
    index: i,
  }));

  // Форматирование чисел для отображения без округления
  const tickFormatCalories = (t) => {
    const numericT = parseFloat(t);
    return numericT ? numericT.toLocaleString() : 0;
  };

  const tickFormatFrigories = (t) => {
    const numericT = parseFloat(t);
    return numericT ? (numericT / scaleFactorFrigories).toLocaleString() : 0;
  };

  return (
    <Box ref={ref} sx={{ width: "100%" }}>
      <VictoryChart
        width={width}
        height={550}
        domainPadding={{ x: [20, 20], y: [20, 20] }}
        theme={VictoryTheme.material}
        padding={{ top: 20, bottom: 100, left: 50, right: 50 }}
        containerComponent={
          <ZoomVoronoiContainer
            zoomDimension="x"
            voronoiDimension="x"
            mouseFollowTooltips
            allowZoom={data.length > 5}
            onActivated={(points) => setHoveredIndex(points[0]?.index)}
            onDeactivated={() => setHoveredIndex(null)}
          />
        }
      >
        <VictoryAxis
          tickFormat={(t) => t}
          tickCount={data.length > 10 ? 10 : data.length}
          style={{ tickLabels: { angle: -45, fontSize: 10, padding: 15 } }}
        />

        <VictoryAxis
          dependentAxis
          orientation="left"
          domain={[minCalories - 1, maxCalories + 1]}
          tickFormat={tickFormatCalories}
          tickCount={5}
          style={{
            axis: { stroke: "#ff3b30" },
            tickLabels: { fontSize: 10, fill: "#ff3b30", dx: -5 },
          }}
        />

        <VictoryAxis
          dependentAxis
          orientation="right"
          domain={[
            minFrigories * scaleFactorFrigories - 1,
            maxFrigories * scaleFactorFrigories + 1,
          ]}
          tickFormat={tickFormatFrigories}
          tickCount={5}
          style={{
            axis: { stroke: "#33ccdb" },
            tickLabels: { fontSize: 10, fill: "#33ccdb", dx: 5 },
          }}
        />

        <VictoryGroup>
          <VictoryLine
            data={caloriesData}
            style={{ data: { stroke: "#ff3b30", strokeWidth: 2.5 } }}
          />

          <VictoryScatter
            data={caloriesData}
            size={({ index }) => (index === hoveredIndex ? 6 : 3)}
            style={{ data: { fill: "#ff3b30", strokeWidth: 1 } }}
            labels={({ datum }) => `${datum.calories} kWh`}
            labelComponent={<VictoryTooltip />}
          />

          <VictoryLine
            data={frigoriesData}
            style={{ data: { stroke: "#33ccdb", strokeWidth: 2.5 } }}
          />

          <VictoryScatter
            data={frigoriesData}
            size={({ index }) => (index === hoveredIndex ? 6 : 3)}
            style={{ data: { fill: "#33ccdb", strokeWidth: 1 } }}
            labels={({ datum }) => `${datum.frigories} kWh`}
            labelComponent={<VictoryTooltip />}
          />
        </VictoryGroup>
      </VictoryChart>
    </Box>
  );
}
