import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { useEffect } from "react";
import PolicyGuideMapping from "./PolycyGuideMapping";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const PolicyGuide = ({ policyMode = false }) => {
  const currentConfig = useSelector(
    (state) => state?.importSlice?.selectedConfiguration
  );
  const [getConfigurationMapping] = store.useLazyGetMappingConfigurationQuery();
  useEffect(() => {
    if (currentConfig && !policyMode) {
      getConfigurationMapping(currentConfig.id);
    }
  }, [currentConfig]);
  useEffect(() => {
    if (policyMode) {
      getConfigurationMapping(window.IMPORT_CONFIGURATION_ID);
    }
  }, [policyMode]);
  const { t } = useTranslation();
  if (currentConfig || policyMode) {
    return (
      <div>
        <PolicyGuideMapping />
        <div style={{ marginTop: "30px" }}>
          <Typography align="center" variant="h5">
            {t("import_basic_rules")}
          </Typography>
          <Typography> {t("import_basic_rules_description")}</Typography>
        </div>
      </div>
    );
  }
  return (
    <div>
      <Typography align="center" variant="h5">
        {t("select_one_import_configuration")}
      </Typography>
    </div>
  );
};

export default PolicyGuide;
