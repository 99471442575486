import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const SelectWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);

  const options = props?.schema?.selectValues?.map((item) => ({
    label: item?.label,
    value: item?.value,
  }));

  useEffect(() => {
    if (value) {
      props.onChange(value.value);
    }
  }, [value]);

  useEffect(() => {
    console.log("propsselic", props);
  }, [props]);
  useEffect(() => {
    console.log("selval", value);
  }, [value]);

  useEffect(() => {
    if (props?.schema?.default) {
      const defVal = options?.filter(
        (item) => item?.value == props?.schema?.default
      );
      setValue(defVal[0]);
    }
  }, [props?.schema?.default]);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={value}
      options={options}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={props?.rawErrors?.length > 0}
          {...params}
          label={`${t(`${props?.name}`)} ${props?.required ? "*" : " "}`}
        />
      )}
    />
  );
};

export default SelectWidget;
