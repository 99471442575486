import { CallToAction } from "@mui/icons-material";
import { store } from "../store";
import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  configurations: null,
  selectedConfiguration: null,
  mappingConfiguration: null,
  importInfo: {},
  connect: false,
  importUuid: null,
  isProcessing: false,
  blockWsConnector: false,
  selectedDtName: "",
  selectedDtVersion: "",
};

const importSlice = createSlice({
  name: "importSlice",
  initialState,
  reducers: {
    setConfiguration(state, action) {
      state.selectedConfiguration = action.payload;
    },
    setImportInfo(state, action) {
      const oldInfo = { ...state.importInfo };
      state.importInfo = { ...oldInfo, ...action.payload };
    },
    resetImportInfo(state) {
      state.importInfo = [];
    },
    setConnect(state, action) {
      state.connect = action.payload;
    },
    setImportUiid(state, action) {
      state.importUuid = action.payload;
    },
    setProcessing(state, action) {
      state.isProcessing = action.payload;
    },
    setMappingConfiguration(state, action) {
      state.mappingConfiguration = action.payload;
    },
    setBlock(state, action) {
      state.blockWsConnector = action.payload;
    },
    setDtImportInfo(state, action) {
      state.selectedDtName = action.payload.name;
      state.selectedDtVersion = action.payload.version;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      store.importEndpoints.getImportConfigurations.matchFulfilled,
      (state, action) => {
        state.configurations = action.payload.data;
      }
    );
    builder.addMatcher(
      store.importEndpoints.getMappingConfiguration.matchFulfilled,
      (state, action) => {
        state.mappingConfiguration = action.payload.data;
      }
    );
  },
});
export const {
  setConfiguration,
  setImportInfo,
  setConnect,
  setImportUiid,
  setProcessing,
  resetImportInfo,
  setMappingConfiguration,
  setBlock,
  setDtImportInfo,
} = importSlice.actions;
export default importSlice.reducer;
