import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setDtImportInfo } from "../../redux/slices/importSlice";
import { useEffect, useState } from "react";

const GuideDigitalTwin = () => {
  const dispatch = useDispatch();
  const currentConfig = useSelector(
    (state) => state?.importSlice?.selectedConfiguration
  );
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  // State to track selected row
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    setSelectedRow(null);
  }, [currentConfig]);

  // Function to handle row click (toggle selection)
  const handleRowClick = (row) => {
    if (selectedRow === row.id) {
      // Deselect if the same row is clicked again
      setSelectedRow(null);
      dispatch(setDtImportInfo({ name: "", version: "" }));
    } else {
      // Select the row and update the info
      setSelectedRow(row.id);
      dispatch(setDtImportInfo({ name: row?.name, version: row?.version }));
    }
  };

  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {t("digital_twin_available")}
      </Typography>
      <TableContainer
        component={Paper}
        sx={{
          maxHeight: "400px",
          overflowY: "auto",
          width: "100%", // Ensure full width
          overflowX: "auto", // Enable horizontal scrolling
        }}
      >
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t("digital_twin")}</TableCell>
              <TableCell>{t("label")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentConfig?.attributes?.digital_twins?.map((row) => (
              <TableRow
                onClick={() => handleRowClick(row)} // Update selected row on click
                key={row.id}
                sx={{
                  backgroundColor:
                    selectedRow === row.id ? "#e3f2fd" : "transparent", // Change background color if selected
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                  cursor: "pointer", // Make the row clickable
                  transition: "background-color 0.3s ease", // Smooth transition for the background color
                }}
              >
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.mapping_label}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default GuideDigitalTwin;
