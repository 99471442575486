import { useSelector, useDispatch } from "react-redux";
import { middleAlert } from "../redux/slices/middleSlice";

const TestRoute = () => {
  const dispatch = useDispatch();

  return (
    <button onClick={() => dispatch(middleAlert("some shnyaga ebanya"))}>
      test
    </button>
  );
};

export default TestRoute;
