import { useEffect, useState } from "react";
import validator from "@rjsf/validator-ajv6";
import { Form } from "@rjsf/mui";
import Grid from "@mui/material/Grid2";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from "@mui/material";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { StyledDataGrid } from "../../pages/StyledDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Chip } from "@material-ui/core";
import { clearEndUserList } from "../../redux/slices/userSlice";
import RolesWidget from "../supply_policies/supply_widgets/RolesWidget";
import { Link, useNavigate } from "react-router-dom";
import { IconButton, Button, Typography } from "@mui/material";
import ModalDialog from "../meters/ModalDialog";
import Tooltip from "@mui/material/Tooltip";
import SingleRoleWidget from "./SingleRoleWidget";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { setUserName } from "../../redux/slices/breadCrumbMeterSlice";
import { resetUserShow } from "../../redux/slices/userSlice";
import IwdDataGrid from "../../pages/IwdDataGrid";
import TrashModal from "../TrashModal";
import { store } from "../../redux/store";
import theme from "../../theme";
import IwdButton from "../../utils/IwdButton";

const fullRowNames = [];

const ObjectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element, i) => {
        if (fullRowNames?.includes(element.name)) {
          return (
            <Grid key={element.id} size={12}>
              {element.content}
            </Grid>
          );
        }

        return (
          <Grid key={element.id} alignItems="center" size={6}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};
const statusEnum = [
  { const: "active" },
  { const: "deactivated" },
  { const: "locked" },
  { const: "inactive" },
];
const Users = () => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [trashId, setTrashId] = useState(null);
  const users = useSelector((state) => state?.userSlice?.users);
  const rowCount = useSelector((state) => state?.userSlice?.count);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);

  const { t } = useTranslation();
  const [getUsers] = store.useLazyGetUsersQuery();
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const paramsList = {
    page: page,
    per_page: perPage,
    ...formData,
  };
  useEffect(() => {
    getUsers(paramsList);
  }, [page, deleteSuccess]);

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setUserName(null));
    dispatch(clearEndUserList());
  }, []);

  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(trashId);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  const onTrashClick = (id) => {
    dispatch(opendModal());
    setTrashId(id);
  };
  useEffect(() => {
    dispatch(setBreadCrumbs("users"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    dispatch(resetUserShow());
  }, []);

  const onSubmitClick = () => {
    getUsers({ ...paramsList });
  };

  const schema = {
    title: "New Device",
    type: "object",
    properties: {
      first_name: {
        type: "string",
        title: t("first_name"),
      },
      last_name: {
        type: "string",
        title: t("last_name"),
      },
      email: {
        type: "string",
        title: t("email_address"),
      },
      role: {
        type: "string",
        title: t("role"),
      },
      status: {
        type: "string",
        oneOf: [...statusEnum],
        title: t("status"),
      },
    },
    required: [],
  };

  const fields = {
    role: SingleRoleWidget,
  };
  const uiSchema = {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    role: {
      "ui:field": "role",
    },
    "ui:submitButtonOptions": {
      submitText: t("search"),

      props: {
        className: "bntListSearch",
        variant: window?.BUTTON_TYPE,
        color: window?.theme?.buttonText,
      },
    },
  };

  useEffect(() => {
    console.log("frmdtsr", formData);
  }, [formData]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },
    {
      field: "first_name",
      headerName: t("first_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "last_name",
      headerName: t("last_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "email",
      headerName: t("Email / Username"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    /*  {
      field: "user_name",
      headerName: t("user_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "display_name",
      headerName: t("display_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "nick_name",
      headerName: t("nick_name"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    }, */
    {
      field: "roles",
      headerName: t("roles"),
      type: "string",
      sortable: false,

      flex: 1,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {params?.value?.map((item) => (
              <Chip
                key={item}
                style={{
                  backgroundColor: theme.palette.chip,
                  color: theme.palette.error.contrastText,
                  margin: "2px",
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: t("Status"),
      type: "string",
      sortable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box sx={{ width: "100%" }}>
            <IconButton
              sx={{ float: "right" }}
              onClick={() => onTrashClick(params.value)}
            >
              <Tooltip title={t("delete_user_title")}>
                <DeleteIcon sx={{ fill: theme.palette.error.main }} />
              </Tooltip>
            </IconButton>
            <IconButton sx={{ float: "right" }}>
              <Link to={`/users/${params?.value}`}>
                <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
              </Link>
            </IconButton>
          </Box>
        );
      },
    },
  ];
  const tableRows = users?.map((item, index) => ({
    id: index,
    first_name: item?.attributes?.first_name ?? "--",
    last_name: item?.attributes?.last_name ?? "--",
    email: item?.attributes?.email ?? "--",
    user_name: item?.attributes?.username ?? "--",
    display_name: item?.attributes?.display_name,
    nick_name: item?.attributes?.nick_name ?? "--",
    roles: item?.attributes?.roles,
    status: item?.attributes?.status,
    actions: item?.id,
  }));

  return (
    <>
      <TrashModal title={t("delete_user_title")} text={t("delete_user_text")} />

      {tableRows && (
        <Box>
          <IwdAuthWrapper
            children={
              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  variant={window.BUTTON_TYPE}
                  sx={{
                    mt: 2,
                    mb: 1,
                  }}
                  onClick={() => navigate("/users/create")}
                >
                  {t("new_user")}
                </Button>
              </Box>
            }
            section={"add_user_btn"}
          />
          <Accordion sx={{ mt: 1, mb: 1 }} defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              {t("search_form")}
            </AccordionSummary>
            <AccordionDetails>
              <Form
                schema={schema}
                fields={fields}
                formData={formData}
                onChange={(changeEvent) => setFormData(changeEvent.formData)}
                onSubmit={onSubmitClick}
                validator={validator}
                uiSchema={uiSchema}
                showErrorList={false}
                noHtml5Validate
              />
            </AccordionDetails>
          </Accordion>
          <IwdDataGrid
            rows={tableRows}
            columns={columns}
            perPage={perPage}
            setPage={setPage}
            page={page}
            count={rowCount}
            sort={false}
            height={600}
            tableName={"users"}
            paginationMode={"server"}
          />
        </Box>
      )}
    </>
  );
};

export default Users;
