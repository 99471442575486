import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import MapLeaflet from "./MapLeaflet";
import { hasCommonValue } from "../../utils/api_params";
import { deviseMessageCleanUp } from "../../redux/slices/deviceMessageSlice";
import { cleanData } from "../../redux/slices/meterReadingSlice";
import { consumptionsCleanUp } from "../../redux/slices/meterSlice";
import { setConsumRange } from "../../redux/slices/meterSlice";
import { useNavigate } from "react-router-dom";
import ManufacturerData from "./ManufacturerData";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";
import ModalDialog from "../meters/ModalDialog";

import { nameTransform } from "../../utils/utilsFunctions";
import { notesActions } from "../../redux/slices/notesSlice";
import { setHeatUnit, setColdUnit } from "../../redux/slices/meterSlice";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import MeterCommands from "./MeterCommands";
import ImpulsiveMeterRegistry from "./impulsiveMeter/ImpulsiveMeterRegistry";
import DeleteConfirmModal from "../../utils/DeleteConfirmModal";
import { setImpulseId } from "../../redux/slices/meterSlice";
import { setMeterTrashId } from "../../redux/slices/meterSlice";
import DeleteButton from "../../utils/DeleteButton";
import { setUpdateMode } from "../../redux/slices/meterSlice";
import MeterValveTab from "./MeterValveTab";
import MeterAssoc from "./MeterAssoc";

import {
  setidentifierTypeValue,
  unsetValue,
} from "../../redux/slices/breadCrumbMeterSlice";
import {
  setFieldsToUpdate,
  setMeterIdToUpdate,
} from "../../redux/slices/meterSlice";
import { setBtnDetector } from "../../redux/slices/condominiumSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
import Registri from "./Registry";
import HeatCostTab from "./HeatCostTab";
import MeterConsumptions from "../form/MeterConsumptions";
import ReadignConsumptionsTab from "../form/ReadignConsumptionsTab";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import InfoCondominum from "./InfoCondominum";
import { setMessage } from "../../redux/slices/snackbarSlice";
import MeterReadingsTab from "../meters/MeterReadingsTab";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import MeterAlarmsTab from "./MeterAlarmsTab";
import MeterNotes from "./MeterNotes";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, width: "100%" }}>{children}</Box>}
    </div>
  );
};

const ValveShow = ({
  apartamentMeterId = null,
  enteredFromCondominium = false,
  impulsiveMeterId = false,
  enteredFromParent = false,
  valveId,

  parentTwinId,
}) => {
  const is1280x720 = useMediaQuery(
    "(max-width: 1280px) and (max-height: 720px)"
  );
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const permessions = window.COMPONENTS;

  const roles = useSelector((state) => state?.userPreferenceSlice.roles);
  const fieldsToUpdate = useSelector(
    (state) => state?.meterSlice?.fieldsToUpdate
  );
  const [disasocValve, { isSuccess: disasocSuccess }] =
    store.useDeleteCompoundTwinMutation();
  const [getMeterShow] = store.useLazyGetMeterShowQuery();
  const currentPath = window.location.pathname;

  const [getCondominiumShow, { isFetching }] =
    store.useLazyCondominiumShowQuery();
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState(false);
  const [valveModal, setValveModal] = useState(false);

  const [msg, setMsg] = useState(null);
  const meterTrashId = useSelector((state) => state?.meterSlice?.trashId);
  const impulsiveId = useSelector((state) => state?.meterSlice?.impulseMeterId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [trigger, result, lastPromiseInfo] = store.useLazyGetDataShowQuery();
  const [getAttributes] = store.useLazyGetMeterAttributesQuery();
  const [value, setValue] = useState(0);
  const [impulsiveTabValue, setImpulsiveTabValue] = useState(0);
  const [meterId, setMeterId] = useState(null);
  const { t } = useTranslation();

  const twinId = result?.data?.data?.attributes?.digital_twin_id;

  const serial = result?.data?.data?.attributes?.fields?.serial;
  const serialNum = result?.data?.data?.attributes?.fields?.serial_number;
  const meterClass = result?.data?.data?.attributes?.fields?.class;
  const fields = result?.data?.data?.attributes?.fields;
  const MeterIdToUpdate = useSelector(
    (state) => state?.meterSlice?.MeterIdToUpdate
  );
  const alarmDate = localStorage.getItem("alarm_date");
  useEffect(() => {
    trigger(`altior/digital_twin/0/instance/${valveId ?? apartamentMeterId}`)
      ?.unwrap()
      ?.then((data) => setMeterId(data?.data?.id))
      ?.catch((error) => {
        console.error("Error occurred:", error);
      });
  }, [id, apartamentMeterId]);

  useEffect(() => {
    dispatch(notesActions.cleanNotes());
  }, []);
  useEffect(() => {
    if (impulsiveMeterId && result?.data?.data) {
      dispatch(setImpulseId(result?.data?.data?.id));
    }
  }, [impulsiveMeterId, result]);

  const onUpdateClick = () => {
    if (!enteredFromCondominium) {
      dispatch(setMeterIdToUpdate(id));
      dispatch(setUpdateMode("default"));

      navigate("edit");
    }
    if (enteredFromCondominium) {
      localStorage.setItem("meterShow", JSON.stringify(result.data.data));
      window.open(`/meters/${MeterIdToUpdate}/edit`, "_blank");
    }
  };
  const onTrashClick = () => {
    setValveModal(true);
  };
  /*   useEffect(() => {
    if (meterTrashId) {
      deleteMeter(enteredFromCondominium ? meterId : meterTrashId);
      dispatch(setMeterTrashId(null));
    }
  }, [meterTrashId]); */
  const index = currentPath.indexOf("/apartamentMeter");
  const trimmedPath =
    index !== -1 ? currentPath.substring(0, index) : currentPath;
  /*   useEffect(() => {
    if (deleteSuccess) {
      dispatch(setMessage(t("successfully_deleted")));

      if (!enteredFromCondominium) {
        navigate("/meters");
      } else {
        getCondominiumShow(id);
        navigate(`${trimmedPath}`);
      }
    }
  }, [deleteSuccess]); */
  const onRawclick = (msg) => {
    setModalMessage(true);
    setMsg(msg);
  };
  useEffect(() => {
    if ((serial || serialNum) && !enteredFromCondominium) {
      dispatch(
        setBreadCrumbs({
          id: `${nameTransform(
            result?.data?.data?.attributes?.fields?.class
          )} - ${result?.data?.data?.attributes?.fields?.serial_number}`,
        })
      );
      return () => {
        dispatch(setBreadCrumbs(null));
      };
    }
  }, [serial, serialNum]);
  useEffect(() => {}, [result]);
  useEffect(() => {
    if (serial) {
      dispatch(setidentifierTypeValue(serial));
    }
  }, [serial]);
  useEffect(() => {
    if (result?.data?.data?.attributes?.fields?.heat_last_reading) {
      dispatch(
        setHeatUnit(
          result?.data?.data?.attributes?.fields?.heat_last_reading?.unit
        )
      );
    }
    if (result?.data?.data?.attributes?.fields?.cold_last_reading) {
      dispatch(
        setColdUnit(
          result?.data?.data?.attributes?.fields?.cold_last_reading?.unit
        )
      );
    }
  }, [result]);
  /*   useEffect(() =>{
    if(result?.data?.data?.attributes?.fields?.cold_last_reading){
      dispatch(setColdUnit(result?.data?.data?.attributes?.fields?.cold_last_reading?.unit))
    }
  },[result]) */

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const impulsiveHandleChange = (event, newValue) => {
    setImpulsiveTabValue(newValue);
  };

  useEffect(() => {
    console.log("parentTwinId", parentTwinId);
  }, [parentTwinId]);

  /*   useEffect(() => {
    if (twinId) {
      getAttributes(twinId);
    }
  }, [twinId]); */
  useEffect(() => {
    if (result?.data?.data) {
      const data = result?.data?.data?.attributes?.fields;
      const fieldsToUpdate = {
        digital_twin_id: result?.data?.data?.attributes?.digital_twin_id,
        key: data?.key,
        address: data?.address ?? "",
        coordinates: {
          lat: data?.coordinates?.coordinates?.[1],
          lng: data?.coordinates?.coordinates?.[0],
        },
        meter_type: result?.data?.data?.attributes?.fields.meter_type,
        security: result?.data?.data?.attributes?.fields?.security?.acl,
        identifier_type:
          result?.data?.data?.attributes?.fields?.identifier_type,
        pod: data?.pod ?? "",
        time_zone: data?.time_zone,
        tags: data?.tags,
        k: data?.k,
        kc: data?.kc,
        kq: data?.kq,
        room: data?.room,
        radiator_c: data?.radiator_c,
        radiator_depth: data?.radiator_depth,
        radiator_height: data?.radiator_height,
        radiator_width: data?.radiator_width,
        factory_converter: data?.factory_converter,
        project10200: data?.project10200,
        radiator_type: data?.radiator_type,
        working_mode: data?.working_mode,
        type: data?.type,
      };
      dispatch(setMeterIdToUpdate(result?.data?.data?.id));
      dispatch(setFieldsToUpdate(fieldsToUpdate));
    }
  }, [result?.data?.data]);

  useEffect(() => {
    if (enteredFromCondominium && fieldsToUpdate && result?.data?.data) {
      localStorage.setItem("fieldsToUpdate", JSON.stringify(fieldsToUpdate));
    }
  }, [enteredFromCondominium, fieldsToUpdate, result]);

  useEffect(() => {
    // breadCrumb value clean up function

    return () => {
      dispatch(unsetValue());
      dispatch(consumptionsCleanUp());
      dispatch(cleanData());
      dispatch(deviseMessageCleanUp());
    };
  }, []);
  const dinamicWidthSelector = () => {
    if (is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1330;
    }
    if (is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1714;
    }
    if (is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1426;
    }
    if (!is1280x720 && appBarIsOpen && !enteredFromCondominium) {
      return 1503;
    }
    if (!is1280x720 && appBarIsOpen && enteredFromCondominium) {
      return 1272;
    }
    if (!is1280x720 && !appBarIsOpen && !enteredFromCondominium) {
      return 1700;
    }
    if (!is1280x720 && !appBarIsOpen && enteredFromCondominium) {
      return 1445;
    }
  };
  useEffect(() => {
    dispatch(setConsumRange(null));
  }, [impulsiveTabValue, value]);
  useEffect(() => {
    if (value == 3 || value == 4 || value == 5) {
      dispatch(setImpulseId(null));
    }
  }, [value]);

  useEffect(() => {}, [result]);
  const tabs = [
    {
      visibility:
        permessions?.["meter_registry"]?.visibility &&
        hasCommonValue(roles, permessions["meter_registry"]?.roles),
      tab: <Tab label={t("registry")} />,
      tabPan: (
        <Registri
          result={result}
          id={id}
          enteredFromCondominium={enteredFromCondominium}
          impulsiveMeterId={impulsiveMeterId}
          valveMode={true}
        />
      ),
      name: "registry",
    },
    {
      visibility:
        permessions?.["meter_block"]?.visibility &&
        hasCommonValue(roles, permessions["meter_block"]?.roles) &&
        !enteredFromCondominium,
      tab: <Tab label={t("info_condominium")} />,
      tabPan: <InfoCondominum meter={result?.data?.data?.attributes?.fields} />,
      name: "info_condominium",
    },
    {
      visibility:
        permessions?.["meter_manufacturer"]?.visibility &&
        hasCommonValue(roles, permessions["meter_manufacturer"]?.roles),
      tab: <Tab label={t("manufacturer_data")} />,
      tabPan: <ManufacturerData result={result} />,
      name: "manufacturer_data",
    },
    {
      visibility:
        permessions?.["meter_reading"]?.visibility &&
        hasCommonValue(roles, permessions["meter_reading"]?.roles),
      tab: <Tab label={t("readings")} />,
      tabPan: (
        <MeterReadingsTab
          meter={result?.data?.data}
          apartamentMeterId={meterId}
          enteredFromCondominium={enteredFromCondominium}
        />
      ),
      name: "meter_reading",
    },
    {
      visibility:
        permessions?.["meter_consumption"]?.visibility &&
        hasCommonValue(roles, permessions["meter_consumption"]?.roles),
      tab: <Tab label={t("consumptions")} />,
      tabPan: (
        <MeterConsumptions
          meterId={id}
          apartamentMeterId={meterId}
          enteredFromCondominium={enteredFromCondominium}
          meter={result?.data?.data}
        />
      ),
      name: "consumptions",
    },
    {
      visibility:
        permessions?.["meter_read_consum"]?.visibility &&
        hasCommonValue(roles, permessions["meter_read_consum"]?.roles),
      tab: <Tab label={t("readings/consumptions")} />,
      tabPan: (
        <ReadignConsumptionsTab
          meter={result?.data?.data}
          apartamentMeterId={meterId}
          enteredFromCondominium={enteredFromCondominium}
        />
      ),
      name: "readings/consumptions",
    },
    {
      visibility:
        permessions?.["meter_assoc"]?.visibility &&
        hasCommonValue(roles, permessions["meter_assoc"]?.roles),
      tab: <Tab label={t("meter_assoc")} />,
      tabPan: <MeterAssoc />,
    },
    {
      visibility:
        permessions?.["meter_commands"]?.visibility &&
        hasCommonValue(roles, permessions["meter_commands"]?.roles),
      tab: <Tab label={t("commands")} />,
      tabPan: <MeterCommands digitalTwinId={twinId} />,
      name: "commands",
    },

    {
      visibility:
        permessions?.["meter_alarms"]?.visibility &&
        hasCommonValue(roles, permessions["meter_alarms"]?.roles),
      tab: <Tab label={t("alarms")} />,
      tabPan: (
        <MeterAlarmsTab
          id={id}
          enteredFromCondominium={enteredFromCondominium}
        />
      ),
      name: "alarms",
    },
    {
      visibility:
        permessions?.["meter_hca"]?.visibility &&
        hasCommonValue(roles, permessions["meter_hca"]?.roles) &&
        result?.data?.data?.attributes?.fields &&
        result?.data?.data?.attributes?.fields?.type == "08",

      tab: <Tab label={t("heat_cost")} />,
      tabPan: (
        <HeatCostTab
          id={id}
          result={result?.data?.data}
          enteredFromCondominium={enteredFromCondominium}
        />
      ),
      name: "heat_cost",
    },

    {
      visibility:
        permessions?.["meter_impulsive"]?.visibility &&
        hasCommonValue(roles, permessions["meter_impulsive"]?.roles) &&
        (meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D"),

      tab: <Tab label={t("input_1")} />,
      tabPan: (
        <ValveShow
          impulsiveMeterId={
            fields?.compound_twins?.[0]?.digital_twin_instance_id
          }
          enteredFromCondominium={enteredFromCondominium}
          apartamentMeterId={apartamentMeterId}
          enteredFromParent={true}
        />
      ),
      name: "input_1",
    },
    {
      visibility:
        permessions?.["meter_impulsive"]?.visibility &&
        hasCommonValue(roles, permessions["meter_impulsive"]?.roles) &&
        (meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D"),
      tab: <Tab label={t("input_2")} />,
      tabPan: (
        <ValveShow
          impulsiveMeterId={
            fields?.compound_twins?.[1]?.digital_twin_instance_id
          }
          enteredFromCondominium={enteredFromCondominium}
          apartamentMeterId={apartamentMeterId}
          enteredFromParent={true}
        />
      ),
      name: "input_2",
    },
    {
      visibility:
        permessions?.["meter_impulsive"]?.visibility &&
        hasCommonValue(roles, permessions["meter_impulsive"]?.roles) &&
        (meterClass === "MAD:00:0D" || meterClass === "EFE:00:0D"),
      tab: <Tab label={t("input_3")} />,
      tabPan: (
        <ValveShow
          impulsiveMeterId={
            fields?.compound_twins?.[2]?.digital_twin_instance_id
          }
          enteredFromCondominium={enteredFromCondominium}
          apartamentMeterId={apartamentMeterId}
          enteredFromParent={true}
        />
      ),
      name: "input_3",
    },
    {
      visibility:
        permessions?.["meter_notes"]?.visibility &&
        hasCommonValue(roles, permessions["meter_notes"]?.roles),
      tab: <Tab label={t("notes")} />,
      tabPan: <MeterNotes enteredFromCondominium={enteredFromCondominium} />,
      name: "notes",
    },
  ];
  const filteredTabs = tabs?.filter((item) => item?.visibility == true);

  useEffect(() => {
    console.log("filteredTabs", filteredTabs);
  }, [filteredTabs]);

  const pulseTabs = [
    {
      visibility:
        permessions?.["pulse_registry"]?.visibility &&
        hasCommonValue(roles, permessions["pulse_registry"]?.roles),
      tab: <Tab label={t("registry")} />,
      tabPan: (
        <ImpulsiveMeterRegistry
          result={result?.data?.data?.attributes?.fields}
          enteredFromParent={enteredFromParent}
        />
      ),
    },
    {
      visibility:
        permessions?.["pulse_readings"]?.visibility &&
        hasCommonValue(roles, permessions["pulse_readings"]?.roles),
      tab: <Tab label={t("readings")} />,
      tabPan: (
        <MeterReadingsTab
          meter={result?.data?.data}
          apartamentMeterId={meterId}
          enteredFromCondominium={enteredFromCondominium}
          enteredFromParent={enteredFromParent}
          impulsiveMeterId={impulsiveId}
        />
      ),
    },
    {
      visibility:
        permessions?.["pulse_consum"]?.visibility &&
        hasCommonValue(roles, permessions["pulse_consum"]?.roles),
      tab: <Tab label={t("consumptions")} />,
      tabPan: (
        <MeterConsumptions
          meterId={id}
          /*  apartamentMeterId={meterId} */
          meter={result?.data?.data}
          enteredFromCondominium={enteredFromCondominium}
          impulsiveMeterId={impulsiveId}
          enteredFromParent={enteredFromParent}
        />
      ),
    },
    {
      visibility:
        permessions?.["pulse_readings_consum"]?.visibility &&
        hasCommonValue(roles, permessions["pulse_readings_consum"]?.roles),
      tab: <Tab label={t("readings/consumptions")} />,
      tabPan: (
        <ReadignConsumptionsTab
          meter={result?.data?.data}
          enteredFromCondominium={enteredFromCondominium}
          enteredFromParent={enteredFromParent}
          impulsiveMeterId={impulsiveId}
        />
      ),
    },
    {
      visibility:
        permessions?.["meter_notes"]?.visibility &&
        hasCommonValue(roles, permessions["meter_notes"]?.roles),
      tab: <Tab label={t("notes")} />,
      tabPan: <MeterNotes enteredFromCondominium={enteredFromCondominium} />,
    },
    {
      visibility:
        permessions?.["meter_assoc"]?.visibility &&
        hasCommonValue(roles, permessions["meter_assoc"]?.roles),
      tab: <Tab label={t("meter_assoc")} />,
      tabPan: <MeterAssoc />,
    },
  ];
  const pulseFilteredTabs = pulseTabs?.filter(
    (item) => item?.visibility == true
  );
  useEffect(() => {
    if (alarmDate) {
      const meterReadTab = tabs?.findIndex(
        (el) => el.name == "readings/consumptions"
      );
      setValue(meterReadTab);
    }
  }, [alarmDate]);
  useEffect(() => {}, [apartamentMeterId]);
  useEffect(() => {
    dispatch(setMeterTrashId(null));
  }, []);

  const openValveModal = () => {
    setValveModal(true);
  };

  const closeValveModal = () => {
    setValveModal(false);
  };
  const onDisasocClick = () => {
    disasocValve({
      parentTwinId: parentTwinId,
      parentId: result?.data?.data?.attributes?.parent_id,
      valveId: result?.data?.data?.id,
    });
  };

  useEffect(() => {
    if (disasocSuccess) {
      dispatch(setMessage(t("disassoc_valve_success")));
      getMeterShow(result?.data?.data?.attributes?.parent_id);
      setValveModal(false);
    }
  }, [disasocSuccess]);

  useEffect(() => {
    console.log("rezikus", result?.data?.data);
  }, [result]);
  return (
    <>
      <ModalDialog
        open={valveModal}
        close={closeValveModal}
        title={"disassoc_valve_action"}
      >
        <Box>
          <Typography>{t("disasoc_valve_text")}</Typography>
          <Box sx={{ mt: 2 }} display={"flex"} justifyContent={"space-between"}>
            <DeleteButton onClick={onDisasocClick} name={t("yes")} />
            <Button onClick={closeValveModal}>{t("no")}</Button>
          </Box>
        </Box>
      </ModalDialog>

      {result?.data && (
        <Box>
          {(serial || serialNum) && meterClass !== "PulseCounter" && (
            <>
              <Box
                display="flex"
                flexDirection={{ xs: "column", sm: "row" }}
                justifyContent="space-between"
              >
                <Box>
                  <Typography variant="h4">
                    {`Meter: ${nameTransform(
                      result?.data?.data?.attributes?.fields?.class ===
                        "lorawan_device"
                        ? "LoRaWAN"
                        : result?.data?.data?.attributes?.fields?.class ?? "--"
                    )} ${result?.data?.data?.attributes?.fields?.serial ?? ""}`}
                  </Typography>
                </Box>
                <Box display="flex" sx={{ mt: { xs: 2, sm: 0 } }}>
                  <IwdAuthWrapper
                    section={"disassoc_valve"}
                    children={
                      <DeleteButton
                        name={t("disassoc_valve_action")}
                        styles={{ height: 40, mt: 1 }}
                        onClick={onTrashClick}
                      />
                    }
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: {
                    xs: "1fr",
                    sm: "1fr 1fr",
                  },
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    gridRow: "1",
                    gridColumn: "span 2",
                    width: "100%",
                  }}
                >
                  <Tabs
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    value={value}
                    onChange={handleChange}
                  >
                    {filteredTabs?.map((item) => item?.tab)}
                  </Tabs>
                  {filteredTabs?.map((item, i) => (
                    <TabPanel index={i} value={value} key={i}>
                      {item?.tabPan}
                    </TabPanel>
                  ))}
                </Box>
              </Box>
            </>
          )}
          {!serial && !serialNum && (
            <Typography align="center" variant="h5">
              {t("meter_not_exists")}
            </Typography>
          )}
        </Box>
      )}

      {meterClass === "PulseCounter" && (
        <>
          {result?.data?.data?.attributes?.fields?.meter_type ? (
            <Box sx={{ width: "100%" }}>
              {!enteredFromParent && (
                <Box
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                  width="100%"
                >
                  <Box width={{ xs: "100%", sm: "50%" }}>
                    <Typography variant="h3">{`Meter: ${nameTransform(
                      result?.data?.data?.attributes?.fields?.class
                    )} ${
                      result?.data?.data?.attributes?.fields?.serial_number
                    }`}</Typography>
                  </Box>
                  <Box display="flex" mt={{ xs: 2, sm: 0 }}>
                    <IwdAuthWrapper
                      section={"delete_meter"}
                      children={
                        <DeleteButton
                          name={t("delete")}
                          styles={{ height: 40, mt: 1 }}
                          onClick={onTrashClick}
                        />
                      }
                    />
                  </Box>
                </Box>
              )}

              <Tabs
                value={impulsiveTabValue}
                onChange={impulsiveHandleChange}
                variant="scrollable"
                scrollButtons="auto"
              >
                {pulseFilteredTabs?.map((item) => item?.tab)}
              </Tabs>

              {pulseFilteredTabs?.map((item, i) => (
                <TabPanel index={i} value={impulsiveTabValue} key={i}>
                  {item?.tabPan}
                </TabPanel>
              ))}
            </Box>
          ) : (
            <Typography sx={{ mt: 2 }} variant="h5" align="center">
              Contatore non abilitato
            </Typography>
          )}
        </>
      )}

      {result?.error?.status === 404 && (
        <Typography align="center" variant="h5">
          {t("meter_not_exists")}
        </Typography>
      )}
    </>
  );
};

export default ValveShow;
