import { prepareParams } from "../../utils";

export const inviteApi = (api) =>
  api.injectEndpoints({
    endpoints: (build) => ({
      getInviteList: build.query({
        query: (params) => {
          return `v1/association_code?${prepareParams(params)}`;
        },
        transformResponse: (response) => {
          const transformedResponse = response.data?.map((item) => ({
            id: item.id,
            code: item.attributes.code,
            deviceId: item.attributes.device_id,
            expiresAt: item.attributes.expires_at,
            used: item.attributes.used,
            user_id: item?.attributes?.user_id,
            usedAt: item.attributes.used_at,
            device: response.included.find(
              (i) => i.id == item.attributes.device_id
            )?.attributes?.fields,
          }));
          return {
            data: transformedResponse,
            count: response.meta.count,
          };
        },
      }),
    }),
  });
