import { useParams } from "react-router-dom";
import { Box, Grid, Typography, Button } from "@mui/material";
import { IwdAuthWrapper } from "../../utils/IwdAuthWrapper";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { setUserUpdateFields } from "../../redux/slices/userSlice";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { opendModal, unsetConfirmed } from "../../redux/slices/trashSlice";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IwdButton from "../../utils/IwdButton";
import DoneIcon from "@mui/icons-material/Done";
import VisibilityIcon from "@mui/icons-material/Visibility";
import BlockAutocomlete from "../widgets/BlockAutocomlete";
import { store } from "../../redux/store";
import UserGroupsWidget from "./UserGroupsWidget";
import { useEffect } from "react";
import BlockGroups from "../condominium/BlockGroups";
import { setCondomiumShow } from "../../redux/slices/condominiumSlice";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import theme from "../../theme";
import { Link } from "react-router-dom";
import DeleteButton from "../../utils/DeleteButton";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { setUserName } from "../../redux/slices/breadCrumbMeterSlice";
import IwdDataGrid from "../../pages/IwdDataGrid";
import TransferList from "../TransferList";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ModalDialog from "../meters/ModalDialog";
import { unsetUserRoles } from "../../redux/slices/userSlice";
import { setTreeExpanded } from "../../redux/slices/condominiumSlice";
import MeterTree from "./MeterTree";
import ApartmentCard from "./ApartmentCard";
import TrashModal from "../TrashModal";
import { setMessage } from "../../redux/slices/snackbarSlice";
const UserShow = () => {
  const [passwordModal, setPasswordModal] = useState(false);
  const apartmentList = useSelector((state) => state?.userSlice?.apartmentList);
  const [getApartmentsList] = store.useLazyGetEndUserApartmentsListQuery();
  const [page, setPage] = useState(1);
  const [resendMail, { isSuccess: resendMailSuccess }] =
    store.useResendMailMutation();
  const [getEndUserList] = store.useLazyGetEnduserDeviceListQuery();
  const [resetPassword, { isSuccess }] = store.useResetPasswordMutation();
  const [codeModal, setCodeModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [getCondominumList] = store.useLazyGetCondominiumListQuery();
  const endUserDevices = useSelector((state) => state?.userSlice?.endUserList);
  const deleteConfirmed = useSelector((state) => state?.trashSlice?.confirmed);
  const condominumList = useSelector(
    (state) => state?.userSlice?.condominumList
  );
  const userRoles = useSelector((state) => state?.userSlice?.userRoles);
  const allRoles = useSelector((state) => state?.aegisSlice?.roles);
  const loggedUserRoles = useSelector(
    (state) => state?.userPreferenceSlice?.roles
  );
  const [deleteUser, { isSuccess: deleteSuccess }] =
    store.useDeleteUserMutation();
  const [getUserShow, { isSuccess: userSuccess }] =
    store.useLazyGetUserShowQuery();
  const [getGrant] = store.useLazyGetGrantQuery();
  const [getAllRoles] = store.useLazyGetAllrolesQuery();
  const { id } = useParams();
  const grantId = useSelector((state) => state?.userSlice?.grantId);
  const { t } = useTranslation();
  const userShow = useSelector((state) => state?.userSlice?.userShow);
  const data = userShow?.attributes;
  useEffect(() => {
    getUserShow(id);
    getGrant(id);
    getAllRoles();
  }, []);
  useEffect(() => {
    getCondominumList(id);

    dispatch(setCondomiumShow(null));
  }, []);

  const roles = ["building_automation"];
  const hasMatch = loggedUserRoles?.some((item) => roles.includes(item));
  const noMatch = !hasMatch;

  /*   function groupBySubCondominium(devices) {
    const subCondominiums = {};
    const otherDevices = [];
    const utilityGroup = {
      name: "Utility",
      identifier: "utility",
      children: [],
    };

    for (const device of devices) {
      if (device.attributes.class === "SubCondominium") {
        const identifier = device.attributes.identifier;
        subCondominiums[identifier] = {
          name: device.attributes.fields.name,
          identifier: identifier,
          children: [],
        };
      } else {
        otherDevices.push(device);
      }
    }

    for (const device of otherDevices) {
      const apartmentId = device.attributes.fields.apartment_id;
      if (apartmentId && subCondominiums[apartmentId]) {
        subCondominiums[apartmentId].children.push(device);
      } else {
        utilityGroup.children.push(device);
      }
    }

    const result = Object.values(subCondominiums);
    if (utilityGroup.children.length > 0) {
      result.push(utilityGroup);
    }

    return result;
  }

  const groupedDevices =
    endUserDevices?.length > 0 && groupBySubCondominium(endUserDevices);

  useEffect(() => {
    console.log("grpddvss", groupedDevices);
  }, [groupedDevices]); */

  useEffect(() => {
    if (userRoles?.includes("end_user")) {
      getEndUserList({
        id: id,
        params: {
          realtion_type: "end_user",
          meters_only: true,
        },
      });
      getApartmentsList({
        id: id,
        params: {
          realtion_type: "end_user",
          apartments_only: true,
        },
      });
    }
  }, [userRoles]);

  const onResendClick = () => {
    const attributes = {};
    resendMail({ userId: id, attributes: attributes });
  };
  useEffect(() => {
    if (userShow) {
      const user = userShow?.attributes;
      const fields = {
        preferred_language:
          user?.preferred_language && user?.preferred_language,
        first_name: user?.first_name,
        last_name: user?.last_name,
        /*    nick_name: user?.nick_name,
        display_name: user?.display_name, */
      };
      dispatch(setUserUpdateFields(fields));
    }
  }, [userShow]);
  useEffect(() => {
    if (userShow) {
      dispatch(
        setBreadCrumbs({
          id: `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`,
        })
      );
    }
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, [userShow]);
  const userStateChek = (state) => {
    switch (state) {
      case "USER_STATE_UNSPECIFIED":
        return t("state_unspecified");
      case "USER_STATE_ACTIVE":
        return t("state_active");
      case "USER_STATE_INACTIVE":
        return t("state_inactive");
      case "USER_STATE_DELETED":
        return t("state_deleted");
      case "USER_STATE_LOCKED":
        return t("state_locked");
      case "USER_STATE_SUSPEND":
        return t("state_suspend");
      case "USER_STATE_INITIAL":
        return t("state_initial");
    }
  };
  const onTrashClick = () => {
    dispatch(opendModal());
  };
  useEffect(() => {
    if (deleteConfirmed) {
      deleteUser(id);
    }
    dispatch(unsetConfirmed());
  }, [deleteConfirmed]);
  useEffect(() => {
    if (deleteSuccess) {
      navigate("/users");
      dispatch(setMessage(t("successfully_deleted")));
    }
  }, [deleteSuccess]);
  useEffect(() => {
    return () => {
      dispatch(unsetUserRoles());
      /*       dispatch(setUserName(null)); */
    };
  }, []);
  useEffect(() => {
    if (userShow) {
      dispatch(
        setUserName(
          `${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`
        )
      );
    }
  }, [userShow]);

  useEffect(() => {
    if (resendMailSuccess) {
      dispatch(setMessage(t("resend_mail_success")));
      setCodeModal(false);
    }
  }, [resendMailSuccess]);

  const onPasswordResetclick = () => {
    const attributes = {
      username: userShow?.attributes?.user_name,
    };
    resetPassword(attributes);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("reset_pass_success")));
      setPasswordModal(false);
    }
  }, [isSuccess]);

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2.5,
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3.2,
      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      flex: 2.3,
      headerAlign: "left",
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "tags",
      headerName: t("tags"),
      type: "string",
      sortable: false,

      flex: 5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {params?.value?.map((item) => (
              <Chip
                key={item}
                style={{
                  backgroundColor: theme.palette.chip,
                  color: theme.palette.error.contrastText,
                  margin: "2px",
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "condominium_name",
      headerName: t("condominium"),
      type: "number",
      sortable: false,
      flex: 2.2,
      headerAlign: "left",
    },
    {
      field: "apartment_name",
      headerName: t("apartment_name"),

      sortable: false,
      flex: 2.2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("inserted_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Link to={`/meters/${params.value?.id}`}>
              <VisibilityIcon sx={{ mt: 1, fill: theme.palette.navIcon }} />
            </Link>
          </Box>
        );
      },
    },
  ];
  const noMatchColumns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      hide: true,
      flex: 1,
    },

    {
      field: "class",
      headerName: t("class"),
      type: "number",
      sortable: false,
      headerAlign: "left",
      flex: 2.5,
    },
    {
      field: "network_address",
      headerName: t("network_address"),
      type: "string",
      nosort: true,
      editable: false,
      flex: 3.2,
      editable: false,
    },
    {
      field: "serial",
      headerName: t("metrological_serial"),
      type: "number",
      sortable: false,
      flex: 2.3,
      headerAlign: "left",
    },
    {
      field: "pod",
      headerName: t("pod"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "tags",
      headerName: t("tags"),
      type: "string",
      sortable: false,

      flex: 5,
      editable: false,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {params?.value?.map((item) => (
              <Chip
                key={item}
                style={{
                  backgroundColor: theme.palette.chip,
                  color: theme.palette.error.contrastText,
                  margin: "2px",
                }}
                label={item}
              />
            ))}
          </Box>
        );
      },
    },
    {
      field: "diameter",
      headerName: t("diameter"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "configdate",
      headerName: t("inserted_at"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },
    {
      field: "last_reeading_date",
      headerName: t("last_reeading_date"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      headerAlign: "left",
    },

    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 2,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Link to={`/meters/${params.value?.id}`}>
              <VisibilityIcon sx={{ mt: 1, fill: theme.palette.navIcon }} />
            </Link>
          </Box>
        );
      },
    },
  ];

  const rows = endUserDevices?.map((item) => ({
    id: item.id ? item.id : "--",

    class: item?.attributes?.class,
    /* ? nameTransform(
          item?.attributes?.class === "lorawan_device"
            ? "LoRaWAN"
            : item?.attributes?.class
        )
      : "--" */ network_address:
      item?.attributes?.fields?.[item?.attributes?.fields?.identifier_type],
    serial: item?.attributes?.fields?.serial_number
      ? item?.attributes?.fields?.serial_number
      : "--",
    /*   metrological_serial: item?.attributes?.fields?.serial ?? "--", */
    pod: item?.attributes?.fields?.pod ?? "--",
    diameter: item?.attributes?.fields?.diameter,
    tags: item?.attributes?.fields?.tags,
    condominium_name: item?.attributes?.fields?.condominium_name ?? "--",
    apartment_name: item?.attributes?.fields?.apartment_name ?? "--",
    last_reeading_date: item?.attributes?.meta_data?.last_seen_at
      ? t("dt", { val: new Date(item?.attributes?.meta_data?.last_seen_at) })
      : "--",
    address: item?.attributes?.fields?.address
      ? item?.attributes?.fields?.address
      : "--",

    configdate: item?.attributes?.inserted_at
      ? t("date_val", { val: new Date(item?.attributes?.inserted_at) })
      : "--",

    actions: {
      id: item.id,
      twinId: item?.attributes?.digital_twin_id,
    },
  }));

  const apartmentProperties = apartmentList?.map((item) => ({
    condominium: item?.attributes?.extra?.condominium_name,
    subcondominium: item?.attributes?.extra?.sub_condominium_name,
    apartment: item?.attributes?.fields?.name,
    link: `/condominiums/${item?.attributes?.extra?.condominium_id}/subcondominiums/${item?.attributes?.extra?.sub_condominium_name}/apartments/${item?.attributes?.fields?.name}`,

    subId: item?.attributes?.extra?.sub_condominium_identifier,
    appId: item?.attributes?.fields?.identifier,
  }));

  return (
    <>
      <ModalDialog
        open={passwordModal}
        title={t("reset_password")}
        cancell={false}
        close={() => setPasswordModal(false)}
      >
        <Box>
          <Typography>{t("reset_user_password_text")}</Typography>
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 1 }}>
            <IwdButton name={t("no")} onClick={() => setPasswordModal(false)} />
            <IwdButton name={t("yes")} onClick={onPasswordResetclick} />
          </Box>
        </Box>
      </ModalDialog>
      <ModalDialog
        open={codeModal}
        title={t("resend_code")}
        cancell={false}
        close={() => setCodeModal(false)}
      >
        <Box>
          <Typography>{t("resend_code_text")}</Typography>
          <Box display={"flex"} justifyContent={"space-between"} sx={{ mt: 1 }}>
            <IwdButton name={t("no")} onClick={() => setCodeModal(false)} />
            <IwdButton name={t("yes")} onClick={onResendClick} />
          </Box>
        </Box>
      </ModalDialog>
      {userSuccess && (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography sx={{ mb: 4 }} variant="h4">
              {`${userShow?.attributes?.first_name} ${userShow?.attributes?.last_name}`}
            </Typography>
            <Box>
              <IwdAuthWrapper
                children={
                  <IwdButton
                    onClick={() => setPasswordModal(true)}
                    styles={{ mr: 1 }}
                    name={t("reset_password")}
                  />
                }
                section={"reset_password_btn"}
              />
              <IwdAuthWrapper
                children={
                  <IwdButton
                    onClick={() => setCodeModal(true)}
                    styles={{ mr: 1 }}
                    name={t("resend_code")}
                  />
                }
                section={"resend_code_btn"}
              />
              <Button
                sx={{ mr: 2 }}
                onClick={() => navigate("edit")}
                variant={window.BUTTON_TYPE}
              >
                {t("update")}
              </Button>
              <DeleteButton name={t("delete")} onClick={onTrashClick} />
            </Box>
          </Box>

          <Box>
            <TrashModal
              title={t("delete_user_title")}
              text={t("delete_user_text")}
            />
            <Grid
              container
              alignItems="center"
              rowSpacing={5}
              direction="row"
              spacing={5}
              xs={12}
            >
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("id")}:
                </Typography>
                <Typography variant="subtitle1">
                  {userShow?.id ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("update_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.change_date
                    ? t("dt", { val: new Date(data?.change_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("creation_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.creation_date
                    ? t("dt", { val: new Date(data?.creation_date) })
                    : "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("Email/Username")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.email ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("preferred_language")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.preferred_language ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("first_name")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.first_name ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("last_name")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.last_name ?? "--"}
                </Typography>
              </Grid>

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("is_email_verified")}:
                </Typography>
                <Typography variant="subtitle1">
                  {!data?.is_email_verified ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid>

              {/*              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("otp")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.otp === false ? (
                    <CloseIcon sx={{ fill: "red" }} />
                  ) : (
                    <DoneIcon sx={{ fill: "green" }} />
                  )}
                </Typography>
              </Grid> */}

              <Grid item xs={4}>
                <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                  {t("state")}:
                </Typography>
                <Typography variant="subtitle1">
                  {data?.state ? userStateChek(data?.state) : "--"}
                </Typography>
              </Grid>

              {condominumList?.length > 0 &&
                userShow?.attributes?.roles?.includes(
                  "building_automation"
                ) && (
                  <Grid item xs={4}>
                    <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>
                      {t("associated_condominiums")}:
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {condominumList?.map((item) => (
                        <Link to={`/condominiums/${item?.id}`} key={item?.id}>
                          <Chip
                            label={item?.attributes?.fields?.name}
                            sx={{
                              ml: 1,
                              mt: 1,
                            }}
                          />
                        </Link>
                      ))}
                    </Box>
                  </Grid>
                )}
              <IwdAuthWrapper
                children={
                  <Grid item xs={12}>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {userRoles?.length > 0 ? (
                          <Box display="flex">
                            <Typography sx={{ mt: 1 }}>
                              {t("user_roles") + ": "}
                            </Typography>
                            <Box>
                              {userRoles?.map((item) => (
                                <Chip label={item} sx={{ ml: 1 }} />
                              ))}
                            </Box>
                          </Box>
                        ) : (
                          t("no_roles")
                        )}
                      </AccordionSummary>
                      <AccordionDetails>
                        {userRoles && allRoles && (
                          <TransferList
                            userRoles={userRoles}
                            allRoles={allRoles?.filter(
                              (item) => !userRoles.includes(item)
                            )}
                            userId={id}
                            grantId={grantId}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                    {userShow?.attributes?.roles?.includes(
                      "building_automation"
                    ) && (
                      <IwdAuthWrapper
                        section={"use_cond_assoc"}
                        children={
                          <Grid item xs={12} sx={{ mt: 5 }}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                {t("assoc_block")}
                              </AccordionSummary>
                              <AccordionDetails>
                                <BlockAutocomlete userId={id} />
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        }
                      />
                    )}
                    <IwdAuthWrapper
                      section={"usershow_usergroups"}
                      children={
                        <Grid item xs={12} sx={{ mt: 5 }}>
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              {t("user_groups")}
                            </AccordionSummary>
                            <AccordionDetails>
                              <UserGroupsWidget />
                            </AccordionDetails>
                          </Accordion>
                        </Grid>
                      }
                    />
                  </Grid>
                }
                section={"roles_transfer"}
              />
              {userRoles?.includes("end_user") && (
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {t("meters")}
                    </AccordionSummary>
                    <AccordionDetails>
                      <IwdDataGrid
                        columns={hasMatch ? columns : noMatchColumns}
                        perPage={10}
                        setPage={setPage}
                        rows={rows}
                        page={page}
                        count={endUserDevices?.length}
                        sort={false}
                        height={600}
                        paginationMode={"client"}
                        tableName={"user_meters"}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
              {userRoles?.includes("end_user") && hasMatch && (
                <Grid item xs={12}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      {t("appartments")}
                    </AccordionSummary>
                    <AccordionDetails>
                      {/*  {apartmentList?.map((item) => (
                        <Link
                          onClick={() => {
                            debugger;
                            localStorage.setItem("TreeExpanded", [
                              "condominum",
                              item?.attributes?.extra
                                ?.sub_condominium_identifier,
                              item?.attributes?.fields?.identifier,
                            ]);
                            dispatch(
                              setTreeExpanded([
                                "condominum",
                                item?.attributes?.extra
                                  ?.sub_condominium_identifier,
                                item?.attributes?.fields?.identifier,
                              ])
                            );
                          }}
                          to={`/condominiums/${item?.attributes?.extra?.condominium_id}/subcondominiums/${item?.attributes?.extra?.sub_condominium_name}/apartments/${item?.attributes?.fields?.name}`}
                        >
                          <Chip
                            sx={{ ml: 2 }}
                            label={item?.attributes?.fields?.name}
                          />
                        </Link>
                      ))} */}
                      <ApartmentCard properties={apartmentProperties} />
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};
export default UserShow;
