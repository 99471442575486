import { store } from "../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setDtImportInfo } from "../../redux/slices/importSlice";
import {
  setConfiguration,
  setMappingConfiguration,
} from "../../redux/slices/importSlice";
import debounce from "lodash.debounce";
import { version } from "os";
const ConfigurationAutocomlete = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [searchValue, setSearchValue] = useState(null);
  const debValue = debounce((e) => {
    setSearchValue(e.target.value);
  }, 500);

  const [getConfig] = store.useLazyGetImportConfigurationsQuery();
  const configs = useSelector((state) => state?.importSlice?.configurations);
  useEffect(() => {
    getConfig();
  }, []);

  const options = configs?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.id,
  }));

  /*   useEffect(() => {
    if (value) {
      props.onChange(value);
    }
  }, [value]); */
  useEffect(() => {
    console.log("props", props?.rawErrors);
  }, [props]);

  useEffect(() => {
    if (value) {
      const selectedConfig = configs?.filter(
        (item) => item?.attributes?.id == value
      );
      dispatch(setConfiguration(selectedConfig?.[0]));
    } else {
      dispatch(setConfiguration(null));
      dispatch(setMappingConfiguration(null));
    }
  }, [value]);
  useEffect(() => {
    if (searchValue) {
      getConfig({ name: searchValue });
    }
  }, [searchValue]);

  useEffect(() => {
    if (!value) {
      getConfig();
    }
  }, [value, searchValue]);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={options}
      onChange={(event, newValue) => {
        dispatch(setDtImportInfo({ name: "", version: "" }));
        setValue(newValue?.value);
      }}
      fullWidth
      renderInput={(params) => {
        return (
          <TextField
            onChange={debValue}
            {...params}
            value={value}
            label={t("import_configuration")}
            InputProps={{
              ...params.InputProps,
            }}
          />
        );
      }}
    />
  );
};

export default ConfigurationAutocomlete;
