import TextField from "@mui/material/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
const TextareWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState();

  useEffect(() => {
    props.onChange(value);
  }, [value]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(props?.schema?.default);
    }
  }, [props?.schema?.default]);

  return (
    <TextField
      fullWidth
      value={value}
      multiline
      rows={6}
      onChange={(e) => setValue(e.target.value)}
      label={t(props?.name)}
    />
  );
};

export default TextareWidget;
