import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  open: false,
  confirmed: false,
};

const middleSlice = createSlice({
  name: "trashSlice",
  initialState,
  reducers: {
    middleAlert(state, action) {},
    secondAlert(state, action) {
      alert(action.payload);
    },
  },
});
export const { middleAlert, secondAlert } = middleSlice.actions;
export default middleSlice.reducer;
