import { Button } from "@mui/material";

const IwdButton = ({ onClick, styles, name, ...rest }) => {
  return (
    <Button
      {...rest}
      onClick={onClick}
      variant={window.BUTTON_TYPE}
      sx={{
        ...styles,
      }}
    >
      {name}
    </Button>
  );
};

export default IwdButton;
