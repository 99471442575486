// The difference with this component is that it includes a role check.
// If the user is an admin, they will be able to select from all roles.
// If the user is not an admin, they can only choose from the roles assigned to them.
import { store } from "../../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import Autocomplete from "@mui/material/Autocomplete";

const SingleRoleWidget = (props) => {
  const admins = ["/admin", "admin"];
  const { t } = useTranslation();
  const [value, setValue] = useState([]);
  const userRoles = useSelector((state) => state?.userPreferenceSlice?.roles);
  const allRoles = useSelector((state) => state?.aegisSlice?.roles);
  const isAdmin = userRoles?.some((role) => admins.includes(role));
  const [getRoles] = store.useLazyGetAllrolesQuery();

  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (isAdmin) {
      getRoles();
    }
  }, [isAdmin]);
  useEffect(() => {
    if (!isAdmin) {
      setOptions(
        userRoles?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    } else if (isAdmin && allRoles?.length > 0) {
      setOptions(
        allRoles?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [isAdmin, allRoles]);
  useEffect(() => {
    props.onChange(value?.value);
  }, [value]);

  useEffect(() => {
    console.log("valuerolix", props);
  }, [props]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(
        props?.schema?.default?.map((item) => ({
          label: item,
          value: item,
        }))
      );
    }
  }, [props?.schema?.default]);

  return (
    <>
      <Autocomplete
        fullWidth
        disablePortal
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        id="combo-box-demo"
        options={options?.length > 0 ? options : []}
        renderInput={(params) => (
          <TextField
            error={props?.rawErrors?.length > 0}
            {...params}
            label={t("role")}
          />
        )}
      />
    </>
  );
};

export default SingleRoleWidget;
