import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  useTheme,
  Grid,
  Chip,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Tooltip from "@mui/material/Tooltip";
import PopoverDashboardCardError from "../Popover";
import PopoverCardTitle from "../PopoverCardTitle";
import {
  deleteCreatedWidget,
  setDragBlcok,
} from "../../../redux/slices/dashboardSlice";
import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTooltip,
  VictoryTheme,
  VictoryScatter,
} from "victory";
import { useTranslation } from "react-i18next";
import { selectLabel } from "./utils/widgetConfigurations";

const LineChartWidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const previewMode = useSelector((state) => state?.widgetsSlice?.previewMode);
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [chartWidth, setChartWidth] = useState(0);
  const [chartHeight, setChartHeight] = useState(0);

  const data =
    widget?.last_update?.data === "NA"
      ? []
      : widget?.last_update?.data
          .filter((item) => !isNaN(item.value))
          .map((item) => ({
            x:
              item.name ??
              t("date_val", { val: new Date(item?.message_date) }) ??
              "",
            y: parseFloat(item.value.toFixed(2)),
          }));

  const previewData = [
    { x: "Group A", y: 400 },
    { x: "Group B", y: 300 },
    { x: "Group C", y: 300 },
    { x: "Group D", y: 200 },
    { x: "Group E", y: 278 },
    { x: "Group F", y: 189 },
    { x: "Group G", y: 250 },
    { x: "Group H", y: 320 },
    { x: "Group I", y: 210 },
    { x: "Group J", y: 275 },
    { x: "Group K", y: 295 },
    { x: "Group L", y: 230 },
  ];
  const getMinMax = (data) => {
    const yValues = data.map((item) => item.y);
    const minY = Math.min(...yValues);
    const maxY = Math.max(...yValues);

    return [minY - (maxY - minY), maxY + (maxY - minY)];
  };

  const [minY, maxY] = data && getMinMax(data);
  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth * 0.75;
      setChartWidth(width);
      setChartHeight(Math.min(width * 0.45, 600));
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };

  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };

  const renderChart = (dataToRender) => (
    <VictoryChart
      width={chartWidth}
      height={chartHeight}
      theme={VictoryTheme.material}
      padding={{ top: 20, bottom: 60, left: 100, right: 60 }}
    >
      <VictoryAxis
        tickFormat={(tick) => tick}
        style={{
          tickLabels: {
            angle: -30,
            fontSize: 18,
            padding: 20,
            fontWeight: "bold",
          },
        }}
      />
      {/* <VictoryAxis
        dependentAxis
        domain={[minY, maxY]}
        tickFormat={(tick) => tick}
        style={{
          tickLabels: {
            fontSize: 25,
            padding: 10,
            fontWeight: "bold",
          },
        }}
      /> */}
      <VictoryLine
        data={dataToRender}
        style={{
          data: { stroke: "#8884d8" },
        }}
      />
      <VictoryScatter
        data={dataToRender}
        size={8}
        style={{
          data: { fill: "#8884d8" },
        }}
        labels={({ datum }) => `${datum.y}`}
        labelComponent={
          <VictoryTooltip
            style={{
              fontSize: 40,
              fontWeight: "bold",
            }}
            flyoutStyle={{
              fill: "#ffffff",
              stroke: "#8884d8",
              strokeWidth: 1,
              padding: 8,
            }}
          />
        }
      />
    </VictoryChart>
  );

  useEffect(() => {
    console.log("", data);
  }, [data]);

  return (
    <Paper
      elevation={5}
      sx={{
        width: "100%",
        height: 390,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ m: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
          edit={edit}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={
                creationPhase
                  ? () => onDelete(widget?.id)
                  : () => onTrashClick(widget?.id)
              }
              sx={{
                float: "right",
                fill: theme.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <>
          {widget.link && data.length > 0 && (
            <a
              onMouseEnter={() => setLinkUnderline(true)}
              onMouseLeave={() => setLinkUnderline(false)}
              style={{
                textDecoration: linkUnderline ? "" : "none",
                marginLeft: 0,
              }}
              href={widget?.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {renderChart(data)}
            </a>
          )}
          {!previewMode &&
            (!widget.link && data.length > 0 ? (
              renderChart(data)
            ) : (
              <Typography variant="h3">NA</Typography>
            ))}
          {previewMode && renderChart(previewData)}
        </>
        {widget?.last_error?.date && !previewMode && (
          <PopoverDashboardCardError error={widget?.last_error} />
        )}
      </Box>
      <Box sx={{ m: 1 }}>
        <Grid
          container
          alignItems="center"
          rowSpacing={1}
          direction="row"
          spacing={1}
          xs={12}
        >
          {widget?.link ? (
            <Grid item xs={2}>
              {widget?.tags?.map((item) => (
                <Chip
                  key={item}
                  style={{ background: theme.palette.chip }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          ) : (
            <Grid item xs={4}>
              {widget?.tags?.map((item) => (
                <Chip
                  key={item}
                  style={{ background: theme.palette.chip }}
                  label={selectLabel(widget?.tags?.[0], theme)}
                />
              ))}
            </Grid>
          )}
          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default LineChartWidget;
