import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import PeopleIcon from "@mui/icons-material/People";
import MenuIcon from "@mui/icons-material/Menu";
import theme from "../theme";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import StorageIcon from "@mui/icons-material/Storage";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InsightsIcon from "@mui/icons-material/Insights";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import GasMeterIcon from "@mui/icons-material/GasMeter";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BalanceIcon from "@mui/icons-material/Balance";
import { setHeaderAppBar } from "../redux/slices/utilsSlice";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import { CloseIwdTreeItem } from "../utils/CloseIwdTreeItem";
import IconButton from "@mui/material/IconButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import GroupIcon from "@mui/icons-material/Group";
import { SimpleTreeView } from "@mui/x-tree-view/SimpleTreeView";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import AllLastReadings from "./AllLastReadings";
import MemoryOutlinedIcon from "@mui/icons-material/MemoryOutlined";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { IwdTreeItem } from "../utils/IwdTreeItem";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MenuItemWithPopover from "./MenuItemPopover";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
import EditNoteIcon from "@mui/icons-material/EditNote";
import PolicyIcon from "@mui/icons-material/Policy";
import CommitIcon from "@mui/icons-material/Commit";
import ExploreIcon from "@mui/icons-material/Explore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import GroupsIcon from "@mui/icons-material/Groups";
import GatewayIndex from "./gateway/GatewayIndex";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import SettingsIcon from "@mui/icons-material/Settings";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { TreeItem } from "@mui/x-tree-view/TreeItem";
import WidgetsIcon from "@mui/icons-material/Widgets";
import ErrorIcon from "@mui/icons-material/Error";
import { HolidayVillage } from "@mui/icons-material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LongNameWrapper from "../utils/LongNameWrapper";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import ReportIcon from "@mui/icons-material/Report";
import IwdCloseItem from "./IwdClosedItem";
import Tmodik from "./Tmodik";
import { useNavigate } from "react-router-dom";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import GroupWorkIcon from "@mui/icons-material/GroupWork";

const drawerWidth = 280;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  marginTop: 3,
  padding: theme.spacing(0, 1),
  /*   ...theme.mixins.toolbar, */
  height: "30px",
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  whiteSpace: "normal",
  overflowWrap: "break-word",
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  background: "#2a2a2a",
  boxSizing: "border-box",
  ...(open && {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  ...(!open && {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  }),
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    ...(open && {
      overflowX: "hidden",
    }),
    ...(!open && {
      width: 0,
      overflowX: "hidden",
    }),
  },
}));
const ClosedDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 70,
  flexShrink: 0,
  whiteSpace: "nowrap",
  background: "#2a2a2a",
  boxSizing: "border-box",
  ...(open && {
    width: 70,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  }),
  ...(!open && {
    width: 0,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
  }),
  "& .MuiDrawer-paper": {
    width: 70,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    ...(open && {
      overflowX: "hidden",
    }),
    ...(!open && {
      width: 0,
      overflowX: "hidden",
    }),
  },
}));

export function SideMenu({
  expanded,
  selected,
  handleToggle,
  handleSelect,
  open,
  handleDrawerClose,
  items,
}) {
  const { t } = useTranslation();
  const bearer = localStorage.getItem("user_token");
  const headerAppBarOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const [imageLoaded, setImageLoaded] = useState(false);
  const logoId = useSelector((state) => state?.configurationSlice?.logoId);
  const favid = useSelector((state) => state?.configurationSlice?.favId);
  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  const navigate = useNavigate();
  return (
    <>
      {headerAppBarOpen ? (
        <Drawer
          variant="permanent"
          open={open}
          classes={{
            paper: {
              background: "#90a4ae",
            },
          }}
        >
          {/*   <Tmodik /> */}
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose} style={{ color: "white" }}>
              <ChevronLeftIcon sx={{ fill: theme.palette.muiMenuIcon }} />
            </IconButton>
          </DrawerHeader>
          {logoId && (
            <Box
              sx={{
                maxHeight: 20,
                mt: 10,
                mb: 5,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <React.Fragment>
                {!imageLoaded && <Box sx={{ height: window?.LOGO?.height }} />}
                <img
                  style={{
                    width: "80%",
                    marginRight: window?.LOGO?.mr,
                    marginLeft: window?.LOGO?.ml,
                    marginTop: window?.LOGO?.mt,
                    marginBottom: window?.LOGO?.mb,
                    fill: window?.LOGO?.color,
                    display: imageLoaded ? "block" : "none",
                  }}
                  src={`${window.BASE_URL}/api/v1/image/${logoId}?token=${bearer}`}
                  onLoad={handleImageLoad}
                  alt="Logo"
                />
              </React.Fragment>
            </Box>
          )}

          <SimpleTreeView
            aria-label="file system navigator"
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            sx={{
              direction: "rtl",
              height: 240,
              mt: 2,
              flexGrow: 1,
              maxWidth: drawerWidth,
              overflowY: "auto",
              scrollbarGutter: "stable",
              "& > *": {
                direction: "ltr",
              },
              "&::-webkit-scrollbar": {
                width: "8px",
                opacity: 0,
                transition: "opacity 0.3s",
              },
              "&:hover::-webkit-scrollbar": {
                opacity: 1,
              },
              "&::-webkit-scrollbar-track": {
                background: theme.palette.primary.main,
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#888",
                borderRadius: "10px",
              },
              "&::-webkit-scrollbar-thumb:hover": {
                background: "#555",
              },
            }}
          >
            <List>
              <IwdTreeItem
                uuid={items.dashboard}
                icon={
                  <DashboardIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                }
                title={t("dashboard")}
                section={"dashboard"}
              >
                <IwdTreeItem
                  uuid={items.metrics}
                  icon={
                    <ScatterPlotIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("metrics")}
                  section={"dashboard"}
                  link={"/metrics"}
                />
                <IwdTreeItem
                  uuid={items.statistics}
                  icon={
                    <InsightsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("statistics")}
                  section={"statistics"}
                  link={"/statistics"}
                />

                <IwdTreeItem
                  uuid={items.widgets}
                  icon={
                    <WidgetsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("widgets")}
                  section={"widgets"}
                  link={"/widgets"}
                />
                <IwdTreeItem
                  uuid={items.dexmenu}
                  icon={
                    <ExploreIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("dex")}
                  section={"dex"}
                  link={"/dex"}
                />
              </IwdTreeItem>

              <IwdTreeItem
                uuid={items.condominiums}
                icon={
                  <HolidayVillage sx={{ fill: theme.palette.muiMenuIcon }} />
                }
                title={t("buildings")}
                section={"condominums"}
                /*    link={"/condominiums"} */
              >
                <IwdTreeItem
                  uuid={items.condominiumList}
                  icon={
                    <FormatListBulletedIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("manage")}
                  section={"condominums"}
                  link={"/condominiums"}
                />
                <IwdTreeItem
                  uuid={items.balances}
                  icon={
                    <BalanceIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("balances")}
                  section={"balance"}
                  link={"/balances"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.meters}
                icon={<GasMeterIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                title={t("meters")}
                section={"devices_meters"}
              >
                <IwdTreeItem
                  uuid={items.metersList}
                  icon={
                    <FormatListBulletedIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("manage")}
                  section={"devices_meters"}
                  link={"/meters"}
                />
                <IwdTreeItem
                  uuid={items.allreadings}
                  icon={
                    <AutoStoriesIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("readings")}
                  section={"all_readings"}
                  link={"/readings"}
                />
                <IwdTreeItem
                  uuid={items.trilliant}
                  icon={
                    <AssessmentIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("Trilliant")}
                  section={"trilliant"}
                  link={"/trilliant"}
                />
                <IwdTreeItem
                  uuid={items.groups}
                  icon={
                    <Diversity2Icon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("groups")}
                  section={"groups_list"}
                  link={"/groups"}
                />
                <IwdTreeItem
                  uuid={items.deviceGroups}
                  icon={
                    <GroupWorkIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("device_groups")}
                  section={"device_groups_list"}
                  link={"/device_groups"}
                />
                <IwdTreeItem
                  uuid={items.errors}
                  icon={<ErrorIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                  title={t("errors")}
                  section={"errors"}
                >
                  <IwdTreeItem
                    uuid={items.errorTypes}
                    icon={
                      <NewReleasesIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("errors_types")}
                    section={"errors"}
                    link={"/errortypes"}
                  />

                  <IwdTreeItem
                    uuid={items.errorAssociation}
                    icon={
                      <ReportIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("error_association")}
                    section={"errors"}
                    link={"/errorassociation"}
                  />
                </IwdTreeItem>
              </IwdTreeItem>

              <IwdTreeItem
                uuid={items.operations}
                icon={
                  <TextSnippetIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                }
                title={t("operations")}
                section={"operations"}
              >
                <IwdTreeItem
                  uuid={items.supply_policies}
                  icon={<PolicyIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                  title={t("supply_policies")}
                  section={"supply_policies"}
                  link={"/supply_policies"}
                />
                <IwdTreeItem
                  uuid={items.userList}
                  icon={<PeopleIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                  title={t("users")}
                  section={"users"}
                  link={"/users"}
                />
                <IwdTreeItem
                  uuid={items.userGroups}
                  icon={<GroupsIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                  title={t("user_groups")}
                  section={"usergroups"}
                  link={"/usergroups"}
                />
                <IwdTreeItem
                  uuid={items.enduser_config}
                  icon={
                    <ManageAccountsIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("enduser_config")}
                  section={"enduser_config"}
                  link={"/enduser"}
                />

                <IwdTreeItem
                  uuid={items.digitalTwins}
                  icon={
                    <MemoryOutlinedIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("digital_twins")}
                  section={"digital_twins"}
                  link={"/digitaltwins"}
                />
                <IwdTreeItem
                  uuid={items.alarms}
                  icon={
                    <NotificationsActiveIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("alarms")}
                  section={"alarms"}
                  link={"/alarms"}
                />
                <IwdTreeItem
                  uuid={items.anomalies}
                  icon={
                    <WarningAmberIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("anomalies")}
                  section={"anomalies"}
                  link={"/anomalies"}
                />
                <IwdTreeItem
                  uuid={items.audit}
                  icon={
                    <VerifiedUserIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("audit")}
                  section={"audit"}
                  link={"/audit"}
                />
                <IwdTreeItem
                  uuid={items.actions}
                  icon={
                    <TaskAltIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("actions")}
                  section={"actions"}
                  link={"/actions"}
                />
                <IwdTreeItem
                  uuid={items.import}
                  icon={
                    <CloudUploadIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("import")}
                  section={"import"}
                  link={"/import"}
                />
                <IwdTreeItem
                  uuid={items.jobs}
                  icon={
                    <WorkHistoryIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("jobs")}
                  section={"jobs"}
                  link={"/jobs"}
                />
              </IwdTreeItem>

              <IwdTreeItem
                uuid={items.worklists}
                icon={<ListAltIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                title={t("AMR")}
                section={"worklist"}
              >
                <IwdTreeItem
                  uuid={items.wokrlistEditor}
                  icon={
                    <EditNoteIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("worklisteditor")}
                  section={"worklist"}
                  link={"worklisteditors"}
                />

                <IwdTreeItem
                  uuid={items.workList}
                  icon={
                    <FactCheckOutlinedIcon
                      sx={{ fill: theme.palette.muiMenuIcon }}
                    />
                  }
                  title={t("worklists")}
                  section={"worklist"}
                  link={"worklists"}
                />
              </IwdTreeItem>
              <IwdTreeItem
                uuid={items.network}
                icon={<RssFeedIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                title={t("network")}
                section={"network"}
              >
                <IwdTreeItem
                  uuid={items.gateways}
                  icon={
                    <SettingsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("gateways")}
                  section={"list_LPWAN"}
                  link={"/gateways"}
                />
                <IwdTreeItem
                  uuid={items.lora}
                  icon={
                    <StorageIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("lora")}
                  section={"lora"}
                  link={"/lora"}
                />
                <IwdTreeItem
                  uuid={items.edge}
                  icon={<CommitIcon sx={{ fill: theme.palette.muiMenuIcon }} />}
                  title={t("edge_controller")}
                  section={"edge"}
                  link={"/edge"}
                />
              </IwdTreeItem>
            </List>
          </SimpleTreeView>

          <Box sx={{ padding: 1, color: theme.palette.version }}>
            Version: {process.env.REACT_APP_VERSION}
          </Box>
        </Drawer>
      ) : (
        <Box>
          <ClosedDrawer
            variant="permanent"
            open={!open}
            classes={{
              paper: {
                background: "#90a4ae",
              },
            }}
          >
            <DrawerHeader>
              <IconButton
                onClick={handleDrawerClose}
                style={{ color: "white", marginLeft: 8 }}
              >
                <MenuIcon sx={{ fill: theme.palette.muiMenuIcon, mt: 2 }} />
              </IconButton>
            </DrawerHeader>

            <SimpleTreeView
              aria-label="file system navigator"
              defaultCollapseIcon={
                <ArrowDropDownIcon sx={{ fill: theme.palette.muiMenuIcon }} />
              }
              defaultExpandIcon={
                <ArrowRightIcon sx={{ fill: theme.palette.muiMenuIcon }} />
              }
              sx={{
                direction: "rtl",
                height: 240,
                mt: 2,
                ml: 0.1,
                flexGrow: 1,
                maxWidth: drawerWidth,
                overflowY: "auto",
                scrollbarGutter: "stable",
                "& > *": {
                  direction: "ltr",
                },
                "&::-webkit-scrollbar": {
                  width: "8px",
                  opacity: 0,
                  transition: "opacity 0.3s",
                },
                "&:hover::-webkit-scrollbar": {
                  opacity: 1,
                },
                "&::-webkit-scrollbar-track": {
                  background: theme.palette.primary.main,
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#888",
                  borderRadius: "10px",
                },
                "&::-webkit-scrollbar-thumb:hover": {
                  background: "#555",
                },
              }}
            >
              <List>
                <MenuItemWithPopover
                  icon={
                    <DashboardIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("dashboard")}
                  popover={true}
                  section={"dashboard"}
                >
                  <MenuItemWithPopover
                    icon={
                      <ScatterPlotIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("metrics")}
                    link={"/metrics"}
                    hui={"/metrics"}
                    section={"dashboard"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <InsightsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("statistics")}
                    link={"/statistics"}
                    section={"statistics"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <WidgetsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("widgets")}
                    section={"widgets"}
                    link={"/widgets"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <ExploreIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("dex")}
                    section={"dex"}
                    link={"/dex"}
                  />
                </MenuItemWithPopover>

                <MenuItemWithPopover
                  popover={true}
                  icon={
                    <HolidayVillage sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("buildings")}
                  section={"condominums"}
                >
                  <MenuItemWithPopover
                    uuid={items.condominiumList}
                    icon={
                      <FormatListBulletedIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("manage")}
                    section={"condominums"}
                    link={"/condominiums"}
                  />
                  <MenuItemWithPopover
                    uuid={items.balances}
                    icon={
                      <BalanceIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("balances")}
                    section={"balance"}
                    link={"/balances"}
                  />
                </MenuItemWithPopover>
                <MenuItemWithPopover
                  popover={true}
                  icon={
                    <GasMeterIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("meters")}
                  section={"devices_meters"}
                >
                  <MenuItemWithPopover
                    icon={
                      <FormatListBulletedIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("manage")}
                    section={"devices_meters"}
                    link={"/meters"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <AutoStoriesIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("readings")}
                    section={"all_readings"}
                    link={"/readings"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <AssessmentIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("Trilliant")}
                    section={"trilliant"}
                    link={"/trilliant"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <Diversity2Icon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("groups")}
                    section={"groups_list"}
                    link={"/groups"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <NewReleasesIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("errors_types")}
                    section={"errors"}
                    link={"/errortypes"}
                  />

                  <MenuItemWithPopover
                    icon={
                      <ReportIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("error_association")}
                    section={"errors"}
                    link={"/errorassociation"}
                  />
                </MenuItemWithPopover>

                <MenuItemWithPopover
                  popover={true}
                  icon={
                    <TextSnippetIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("operations")}
                  section={"operations"}
                >
                  <MenuItemWithPopover
                    popover={true}
                    icon={
                      <PolicyIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("supply_policies")}
                    section={"supply_policies"}
                    link={"/supply_policies"}
                    singleElement
                  />
                  <MenuItemWithPopover
                    icon={
                      <PeopleIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("users")}
                    section={"users"}
                    link={"/users"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <GroupsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("user_groups")}
                    section={"usergroups"}
                    link={"/usergroups"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <ManageAccountsIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("enduser_config")}
                    section={"enduser_config"}
                    link={"/enduser"}
                  />

                  <MenuItemWithPopover
                    icon={
                      <MemoryOutlinedIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("digital_twins")}
                    section={"digital_twins"}
                    link={"/digitaltwins"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <NotificationsActiveIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("alarms")}
                    section={"alarms"}
                    link={"/alarms"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <WarningAmberIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("anomalies")}
                    section={"anomalies"}
                    link={"/anomalies"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <VerifiedUserIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("audit")}
                    section={"audit"}
                    link={"/audit"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <TaskAltIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("actions")}
                    section={"actions"}
                    link={"/actions"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <WorkHistoryIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("jobs")}
                    section={"jobs"}
                    link={"/jobs"}
                  />
                </MenuItemWithPopover>

                <MenuItemWithPopover
                  popover={true}
                  icon={
                    <ListAltIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("AMR")}
                  section={"worklist"}
                >
                  <MenuItemWithPopover
                    icon={
                      <EditNoteIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("worklisteditor")}
                    section={"worklist"}
                    link={"worklisteditors"}
                  />

                  <MenuItemWithPopover
                    icon={
                      <FactCheckOutlinedIcon
                        sx={{ fill: theme.palette.muiMenuIcon }}
                      />
                    }
                    title={t("worklists")}
                    section={"worklist"}
                    link={"worklists"}
                  />
                </MenuItemWithPopover>
                <MenuItemWithPopover
                  popover={true}
                  icon={
                    <RssFeedIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                  }
                  title={t("network")}
                  section={"network"}
                >
                  <MenuItemWithPopover
                    icon={
                      <SettingsIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("gateways")}
                    section={"list_LPWAN"}
                    link={"/gateways"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <StorageIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("lora")}
                    section={"lora"}
                    link={"/lora"}
                  />
                  <MenuItemWithPopover
                    icon={
                      <CommitIcon sx={{ fill: theme.palette.muiMenuIcon }} />
                    }
                    title={t("edge_controller")}
                    section={"edge"}
                    link={"/edge"}
                  />
                </MenuItemWithPopover>
              </List>
            </SimpleTreeView>
            <Box maxHeight={110} sx={{ mb: 1 }}>
              <React.Fragment>
                {/*   {!imageLoaded && (
                  <Box sx={{ height: window?.LOGO?.height }}></Box>
                )} */}
                <img
                  style={{
                    height: 50,
                    width: 50,
                    marginRight: 0,
                    marginLeft: 9,
                    marginTop: 20,
                    marginBottom: 12,
                    fill: 10,
                    display: imageLoaded ? "block" : "none",
                  }}
                  src={`${window.BASE_URL}/api/v1/image/${logoId}?token=${bearer}`}
                  onLoad={handleImageLoad}
                  alt="Logo"
                />
              </React.Fragment>
            </Box>
          </ClosedDrawer>
        </Box>
      )}
    </>
  );
}
