import React from "react";
import Box from "@mui/material/Box";

import { useEffect } from "react";
import { useState } from "react";
import { store } from "../redux/store";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import DeviceGroupSearchProvider from "../components/deviceGroup/DeviceGroupSearchProvider";
import DeviceGroupSearchBar from "../components/deviceGroup/DeviceGroupSearchBar";
import DeviceGroupTable from "../components/deviceGroup/DeviceGroupTable";
import { useNavigate } from "react-router-dom";
import { setBreadCrumbs } from "../redux/slices/breadCrumbMeterSlice";

const DeviceGroups = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [getDeviceGroup] = store.useLazyGetDevicegroupsIndexQuery();

  const params = {
    page: page,
    perPage: perPage,
  };
  useEffect(() => {
    getDeviceGroup(params);
  }, [page]);
  useEffect(() => {
    dispatch(setBreadCrumbs("DeviceGroups"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  return (
    <Box>
      <Box sx={{ marginBottom: 5 }}></Box>
      <DeviceGroupTable page={page} perPage={perPage} setPage={setPage} />
    </Box>
  );
};

export default DeviceGroups;
