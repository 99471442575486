import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";

const TagsWidget = (props) => {
  const [value, setValue] = useState(null);
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  useEffect(() => {
    getByName(["tag"]);
  }, []);

  const options = resp?.data?.data?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.name,
  }));

  useEffect(() => {
    if (value) {
      props?.onChange(value?.map((item) => item));
    }
  }, [value]);
  const pickedValues = value?.map((item) => item);
  useEffect(() => {
    console.log("tagulya", props);
  }, [props]);
  useEffect(() => {
    if (props?.schema?.default?.length > 0) {
      setValue(props?.schema?.default?.map((item) => item));
    }
  }, [props?.schema?.default]);
  return (
    <Autocomplete
      multiple
      disablePortal
      value={
        value?.length > 0
          ? value.map((val) => ({ label: val, value: val }))
          : []
      }
      id="combo-box-demo"
      options={options?.filter((item) => !pickedValues?.includes(item?.value))}
      onChange={(event, newValue) => {
        setValue(newValue?.map((item) => item?.value));
      }}
      fullWidth
      renderInput={(params) => <TextField {...params} label="Tags" />}
    />
  );
};

export default TagsWidget;
