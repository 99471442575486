import { middleAlert, secondAlert } from "../slices/middleSlice";

const testMiddleware = (store) => {
  return (next) => (action) => {
    if (middleAlert.match(action)) {
      store.dispatch(secondAlert(`${action.payload}+ huuuuuuuui`));
    }
    next(action);
  };
};
export default testMiddleware;
