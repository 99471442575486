import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { setMeterShow } from "../../redux/slices/meterSlice";
import Chip from "@mui/material/Chip";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useTranslation } from "react-i18next";
import DeviceMap from "./MapLeaflet";
import { Box } from "@mui/system";
const getMeterConfig = (
  twinId,
  fields,
  t,
  meterType,
  showPassword,
  setShowPassword,
  result
) => {
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const excludedKeys = [];
  const meterConfig = window.METER[twinId] || window.METER.default;
  const offMap =
    fields?.coordinates?.coordinates[0] === 0 &&
    fields?.coordinates?.coordinates[1] === 0;

  const meterTypeWrapper = (type) => {
    switch (type) {
      case "Frigorie":
        return t("frigories");
      case "Calorie":
        return t("calories");
      case "CALORIE/FRIGORIE":
      case "CALORIE / FRIGORIE":
        return t("cal_fri");
      case "RIPARTITORE":
        return t("distributor");
      default:
        return t(type);
    }
  };

  // Sort meterConfig fields by order
  const sortedConfig = Object.entries(meterConfig)
    .filter(([_, value]) => value.show) // Ensure we only include fields with "show: true"
    .sort((a, b) => (a[1].order || 999) - (b[1].order || 999)); // Sort by order field

  return (
    <Grid container>
      {/* Loop through the sorted fields and render */}
      <Grid
        container
        alignItems="center"
        rowSpacing={5}
        direction="row"
        spacing={5}
        xs={offMap ? 12 : 8}
      >
        {sortedConfig.map(([key, value]) => {
          if (key === "security" && fields?.security?.acl?.length > 0) {
            return (
              <Grid item xs={meterConfig.security.sizeShow || 3} key={key}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("security")}:
                </Typography>
                {fields?.security?.acl?.map((item, index) => (
                  <Box key={index} display={"flex"} sx={{ mt: 1 }}>
                    <Typography>{item.name} </Typography>
                    {item?.scopes?.map((scope, scopeIndex) => (
                      <Chip key={scopeIndex} label={scope} sx={{ ml: 2 }} />
                    ))}
                  </Box>
                ))}
              </Grid>
            );
          }

          if (key === "tags" && fields?.tags?.length > 0) {
            return (
              <Grid item xs={meterConfig.tags?.sizeShow || 3} key={key}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("tags")}:
                </Typography>
                <Typography variant="subtitle1">
                  {Array.isArray(fields?.tags) ? (
                    fields?.tags?.map((item, index) => (
                      <Chip key={index} label={meterTypeWrapper(item)} />
                    ))
                  ) : (
                    <Chip label={meterTypeWrapper(fields?.tags)} />
                  )}
                </Typography>
              </Grid>
            );
          }
          if (key === "alarms" && fields?.alarms?.length > 0) {
            return (
              <Grid item xs={meterConfig.alarms?.sizeShow || 3} key={key}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("alarm_list")}:
                </Typography>
                <Typography variant="subtitle1">
                  {fields?.alarms?.map((item, index) => (
                    <Chip
                      key={index}
                      color="error"
                      sx={{ mt: 1 }}
                      label={item}
                    />
                  ))}
                </Typography>
              </Grid>
            );
          }
          if (
            key === "valve_status_v2" &&
            fields?.valve_status_v2?.length > 0
          ) {
            return (
              <Grid
                item
                xs={meterConfig.valve_status_v2?.sizeShow || 3}
                key={key}
              >
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("valve_status")}:
                </Typography>
                <Box width={"100%"} display={"flex"}>
                  {fields?.valve_status_v2?.map((item, index) => (
                    <Chip key={index} sx={{ ml: 1, mt: 1 }} label={t(item)} />
                  ))}
                </Box>
              </Grid>
            );
          }
          if (key === "battery_level" && fields?.battery_level) {
            return (
              <Grid
                item
                xs={meterConfig.battery_level?.sizeShow || 3}
                key={key}
              >
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("battery_level")}:
                </Typography>
                <Typography variant="subtitle1">
                  {fields?.battery_level
                    ? `${fields?.battery_level?.value} ${fields?.battery_level?.unit}`
                    : " "}
                </Typography>
                {fields?.battery_level?.datetime_utc && (
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                  >
                    {t("dt", {
                      val: new Date(fields?.battery_level?.datetime_utc),
                    })}
                  </Typography>
                )}
              </Grid>
            );
          }
          {
            key === "configuration_date" && fields?.placing_data_utc && (
              <Grid item xs={meterConfig.configuration_date?.sizeShow || 3}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("configuration_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {t("dt", {
                    val: new Date(fields?.placing_data_utc),
                  })}
                </Typography>
              </Grid>
            );
          }

          {
            key === "replacement_date" && fields?.replacement_date && (
              <Grid item xs={meterConfig.replacement_date?.sizeShow || 3}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("replacement_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {t("dt_m", {
                    val: new Date(fields?.replacement_date),
                  })}
                </Typography>
                {fields?.last_previous_reading_value && (
                  <Typography variant="subtitle2">
                    {`${t("last_prev_val")}: ${
                      fields?.last_previous_reading_value
                    }`}
                  </Typography>
                )}
              </Grid>
            );
          }

          if (key === "coordinates" && fields?.coordinates) {
            return (
              <Grid item xs={meterConfig.coordinates?.sizeShow || 3} key={key}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("coordinates")}:
                </Typography>
                <Typography variant="subtitle1">{`Lat : ${fields?.coordinates?.coordinates[1]}`}</Typography>
                <Typography variant="subtitle1">{`Lng : ${fields?.coordinates?.coordinates[0]}`}</Typography>
              </Grid>
            );
          }
          if (key === "reverse") {
            if (meterType === "waterMeter") {
              return (
                <Grid item xs={meterConfig.reverse?.sizeShow} key={key}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                  >
                    {t("mounted_upside_down")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {fields?.reverse === true ? "True" : "False"}
                  </Typography>
                </Grid>
              );
            }
            if (meterType === "colorMeter") {
              return (
                <Grid item xs={meterConfig.reverse?.sizeShow}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                  >
                    {t("installed")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {fields?.reverse === true ? "Mandata" : "Ritorno"}
                  </Typography>
                </Grid>
              );
            }
            return null;
          }

          if (key == "last_reading") {
            if (meterType === "colorMeter") {
              return (
                <>
                  {/* Last reading */}
                  {fields?.heat_last_reading && (
                    <Grid item xs={meterConfig.last_reading?.sizeShow || 3}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("last_reading_heat_val")}:
                      </Typography>
                      <Typography variant="subtitle1">
                        {fields?.heat_last_reading?.value
                          ? fields?.heat_last_reading?.unit === "Wh"
                            ? `${t("number", {
                                val: fields?.heat_last_reading?.value / 1000,
                              })} ${"kWh"} `
                            : `${t("number", {
                                val: fields?.heat_last_reading?.value,
                              })} ${fields?.heat_last_reading?.unit} `
                          : " "}
                      </Typography>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {fields?.heat_last_reading?.value
                          ? t("dt", {
                              val: new Date(
                                fields?.heat_last_reading?.datetime_utc
                              ),
                            })
                          : " "}
                      </Typography>
                    </Grid>
                  )}

                  {fields?.cold_last_reading && (
                    <Grid item xs={meterConfig.last_reading?.sizeShow || 3}>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {t("last_reading_cold_val")}:
                      </Typography>
                      <Typography variant="subtitle1">
                        {fields?.cold_last_reading?.value
                          ? fields?.cold_last_reading?.unit === "Wh"
                            ? `${t("number", {
                                val: fields?.cold_last_reading?.value / 1000,
                              })} ${"kWh"} `
                            : `${t("number", {
                                val: fields?.cold_last_reading?.value,
                              })} ${fields?.cold_last_reading?.unit} `
                          : " "}
                      </Typography>
                      <Typography
                        style={{ fontWeight: "bold" }}
                        variant="subtitle2"
                      >
                        {fields?.cold_last_reading?.value
                          ? t("dt", {
                              val: new Date(
                                fields?.cold_last_reading?.datetime_utc
                              ),
                            })
                          : " "}
                      </Typography>
                    </Grid>
                  )}
                </>
              );
            }

            if (meterType === "waterMeter" || meterType === "heatCost") {
              return (
                <Grid item xs={meterConfig.last_reading?.sizeShow}>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                  >
                    {t("last_reading_value")}:
                  </Typography>
                  <Typography variant="subtitle1">
                    {fields?.last_reading !== null
                      ? `${t("number", {
                          val:
                            fields?.type == "08"
                              ? fields?.last_reading?.value?.toFixed(0)
                              : fields?.last_reading?.value,
                        })} ${
                          fields?.last_reading?.unit &&
                          fields?.last_reading?.unit !== "J"
                            ? fields?.last_reading?.unit == "m^3"
                              ? "m³"
                              : fields?.last_reading?.unit
                            : ""
                        } ${
                          fields?.last_reading?.units &&
                          fields?.last_reading?.units !== "J"
                            ? fields?.last_reading?.units == "m^3"
                              ? "m³"
                              : fields?.last_reading?.units
                            : ""
                        }  `
                      : " "}
                  </Typography>
                  <Typography
                    style={{ fontWeight: "bold" }}
                    variant="subtitle2"
                  >
                    {fields?.last_reading
                      ? t("dt", {
                          val: new Date(fields?.last_reading?.datetime_utc),
                        })
                      : " "}
                  </Typography>
                </Grid>
              );
            }
            return null;
          }
          if (key === "key" && fields?.key) {
            return (
              <Grid item xs={meterConfig.key?.sizeShow} key={key}>
                <Typography
                  style={{ width: "100%", fontWeight: "bold" }}
                  variant="subtitle2"
                >
                  {t("key")}:
                </Typography>
                <Input
                  style={{ width: "100%" }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={fields?.key}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </Grid>
            );
          }
          if (key == "inserted_at") {
            return (
              <Grid item xs={meterConfig.inserted_at?.sizeShow}>
                <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                  {t("insertion_date")}:
                </Typography>
                <Typography variant="subtitle1">
                  {t("dt", {
                    val: new Date(result.inserted_at),
                  })}
                </Typography>
              </Grid>
            );
          }

          // For other fields, render as they are
          return (
            <Grid item xs={value.sizeShow} key={key}>
              <Typography style={{ fontWeight: "bold" }} variant="subtitle2">
                {t(key)}:
              </Typography>
              <Typography variant="subtitle1">
                {typeof fields[key] === "object"
                  ? JSON.stringify(fields[key])
                  : fields[key] || "N/A"}
              </Typography>
            </Grid>
          );
        })}
      </Grid>

      {/* If coordinates are not off-map, display DeviceMap */}
      {!offMap && fields?.coordinates && (
        <Grid item xs={4} sx={{ ml: 4 }}>
          <Box>
            <DeviceMap pos={fields?.coordinates} />
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

const MeterRegisty = (result, lora = false, valveMode = false) => {
  const { t } = useTranslation();
  const [meterType, setMeterType] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const fields = result?.result?.data?.data?.attributes?.fields;
  const key = fields?.identifier_type;
  const type = fields?.type;
  const twinId = fields?.digital_twin_id;

  useEffect(() => {
    console.log("resultamba", fields);
  }, [fields]);

  useEffect(() => {
    switch (type) {
      case "08":
      case "8":
        setMeterType("heatCost");
        break;
      case "6":
      case "06":
      case "7":
      case "07":
      case "21":
      case "15":
      case "22":
      case "16":
        setMeterType("waterMeter");
        break;
      case "10":
      case "0A":
      case "11":
      case "0B":
      case "12":
      case "0C":
      case "13":
      case "0D":
        setMeterType("colorMeter");
        break;
    }
  }, [type]);

  return getMeterConfig(
    twinId,
    fields,
    t,
    meterType,
    showPassword,
    setShowPassword,
    result?.result?.data?.data?.attributes
  );
};

export default MeterRegisty;
