import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  TextField,
  Box,
} from "@mui/material";
import { Apartment, Business, HomeWork } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { setTreeExpanded } from "../../redux/slices/condominiumSlice";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import IwdDataGrid from "../../pages/IwdDataGrid";
import VisibilityIcon from "@mui/icons-material/Visibility";
export default function PropertyGrid({
  properties,
  setApartmentpage,
  apartmentPage,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const perPage = 10;
  // Filter properties based on the search query
  const filteredProperties = Array.isArray(properties)
    ? properties.filter((property) =>
        Object.values(property).some((value) =>
          value?.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : [];

  useEffect(() => {
    console.log("filteredProperties", filteredProperties);
  }, [filteredProperties]);

  useEffect(() => {
    localStorage.removeItem("TreeExpanded");
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      sortable: false,
      flex: 1,
      hide: true,
    },

    {
      field: "condominium",
      headerName: t("condominium"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "subcondominium",
      headerName: t("subcondominiums"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "apartment",
      headerName: t("apartament"),
      type: "string",
      sortable: false,
      editable: false,
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: t("actions"),
      type: "number",
      sortable: false,
      editable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            sx={{ width: "100%" }}
            display={"flex"}
            justifyContent={"flex-end"}
          >
            <Link
              onClick={() => {
                debugger;
                localStorage.setItem("TreeExpanded", [
                  "condominum",
                  params?.value?.subId,
                  params?.value?.appId,
                ]);

                dispatch(
                  setTreeExpanded([
                    "condominum",
                    params?.value?.subId,
                    params?.value?.appId,
                  ])
                );
              }}
              to={params.value?.link}
            >
              <VisibilityIcon sx={{ fill: theme.palette.navIcon }} />
            </Link>
          </Box>
        );
      },
    },
  ];

  const rows = filteredProperties?.map((item, i) => ({
    id: i,
    condominium: item?.condominium,
    subcondominium: item?.subcondominium,
    apartment: item?.apartment,
    actions: {
      link: item?.link,
      subId: item?.subId,
      appId: item?.appId,
    },
  }));

  return (
    <Box>
      {/*  <TextField
        label={t("search")}
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ mb: 2 }}
      /> */}

      <IwdDataGrid
        columns={columns}
        perPage={10}
        count={properties?.length}
        setPage={setPage}
        rows={rows ?? []}
        page={page}
        sort={false}
        height={600}
        paginationMode={"client"}
        tableName={"user_apartments"}
      />
    </Box>
  );
}
