import React, { useEffect, useState } from "react";
import { VictoryPie } from "victory";
import Tooltip from "@mui/material/Tooltip";
import {
  deleteCreatedWidget,
  setDragBlcok,
} from "../../../redux/slices/dashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectLabel } from "./utils/widgetConfigurations";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Grid,
  Typography,
  useTheme,
  Paper,
  IconButton,
  Chip,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import PopoverCardTitle from "../PopoverCardTitle";

const COLORS = [
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#9966FF",
  "#8B4513",
  "#00FF00",
  "#00008B",
  "#FFA07A",
  "#FFD700",
  "#ADFF2F",
  "#FF4500",
  "#6A5ACD",
  "#2E8B57",
  "#FF69B4",
  "#708090",
  "#4682B4",
  "#D2691E",
  "#9ACD32",
  "#F4A460",
];

const PieCharWidget = ({
  widget,
  edit,
  setTrashModal,
  setTrashId,
  creationPhase,
}) => {
  const [linkUnderline, setLinkUnderline] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth * 0.3;
      setChartWidth(width);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = Array.isArray(widget?.last_update?.data)
    ? widget?.last_update?.data.map((item) => ({
        x: item.name,
        y: +item.value,
      }))
    : [];

  const onTrashClick = (id) => {
    setTrashModal(true);
    setTrashId(id);
  };

  const onDelete = (id) => {
    dispatch(deleteCreatedWidget(id));
  };

  return (
    <Paper
      elevation={5}
      sx={{
        width: "100%",
        height: 400,
        borderRadius: 5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ m: 1 }}>
        <PopoverCardTitle
          title={widget?.name}
          description={widget?.description}
          edit={edit}
        />
        <Divider />
        {edit && (
          <IconButton sx={{ float: "right" }}>
            <DeleteIcon
              onMouseEnter={() => dispatch(setDragBlcok(true))}
              onMouseLeave={() => dispatch(setDragBlcok(false))}
              onClick={
                creationPhase
                  ? () => onDelete(widget?.id)
                  : () => onTrashClick(widget?.id)
              }
              sx={{
                fill: theme.palette.error.main,
                cursor: "pointer",
              }}
            />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          flex: "1 1 auto",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            flex: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {data.length > 0 ? (
            <VictoryPie
              data={data}
              colorScale={COLORS}
              width={chartWidth}
              height={chartWidth}
              labels={[]}
              style={{
                data: { stroke: "#fff", strokeWidth: 1 },
              }}
            />
          ) : (
            <></>
          )}
        </Box>

        <Box
          sx={{
            flex: 6,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            padding: "16px",
            ml: 5,
            maxHeight: 300,
            overflow: data.length > 10 ? "auto" : "visible",
            paddingTop: "8px",
            boxSizing: "border-box",
          }}
        >
          {data.length > 0 &&
            data
              .sort((a, b) => b.y - a.y)
              .map((datum, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "8px",
                  }}
                >
                  <Box
                    sx={{
                      width: 12,
                      height: 12,
                      backgroundColor: COLORS[index % COLORS.length],
                      borderRadius: "50%",
                      marginRight: "8px",
                    }}
                  />
                  <Typography
                    variant="body2"
                    sx={{ marginRight: "8px", fontWeight: "bold" }}
                  >
                    {datum.x}
                  </Typography>
                  <Typography variant="body2">{`${datum.y}%`}</Typography>
                </Box>
              ))}
        </Box>
      </Box>
      {data.length < 1 && (
        <Box
          display={"flex"}
          width={"100%"}
          height={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography variant="h1">NA</Typography>
        </Box>
      )}

      <Box sx={{ m: 1 }}>
        <Grid container alignItems="center" spacing={1}>
          <Grid item xs={4}>
            {widget?.tags?.map((item) => (
              <Chip
                key={item}
                style={{
                  background: theme.palette.chip,
                }}
                label={selectLabel(item, theme)}
              />
            ))}
          </Grid>
          <Grid item xs={8}>
            <Tooltip title={t("last_update")}>
              <Typography
                textAlign="end"
                variant="body2"
                sx={{ fontSize: "0.75rem" }}
              >
                {t("dt", { val: new Date(widget?.last_update?.date) })}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default PieCharWidget;
