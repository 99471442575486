import {
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";
import { digitalTwinApi } from "../../redux/slices/digitalTwinApi";

const MandatoryIcon = ({ mandatory }) => {
  const { t } = useTranslation();
  return (
    <Tooltip title={mandatory ? t("mandatory") : t("optional")}>
      {mandatory ? (
        <StarRateIcon color="warning" sx={{ fontSize: 20 }} />
      ) : (
        <EditIcon color="warning" sx={{ fontSize: 20 }} />
      )}
    </Tooltip>
  );
};

const setOpacity = (out, digitalTwinName, digitalTwinVersion) => {
  debugger;
  const specificItem = out.find(
    ({ digital_twin_name, digital_twin_version }) =>
      digital_twin_name == digitalTwinName &&
      digital_twin_version == digitalTwinVersion
  );
  console.log("aaa", digitalTwinName);
  if (digitalTwinName && digitalTwinVersion) {
    if (specificItem) {
      return "100%";
    } else {
      return "40%";
    }
  } else {
    return "100%";
  }
};

const GuideMapping = ({ digitalTwinName, digitalTwinVersion }) => {
  const mappingConfigurations = useSelector(
    (state) => state?.importSlice?.mappingConfiguration
  );
  const { t } = useTranslation();
  const isMobile = useMediaQuery("(max-width:600px)");

  return (
    <Box sx={{ mt: 4, p: 1, margin: "auto" }}>
      <Typography variant={isMobile ? "h6" : "h5"} gutterBottom>
        {t("import_column_list")}
      </Typography>
      <List sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <ListItem
          secondaryAction={<MandatoryIcon mandatory={true} />}
          sx={{ bgcolor: "#f5f5f5", borderRadius: 2 }}
        >
          label
        </ListItem>
        {mappingConfigurations?.map(
          ({ attributes: { in: inValue, mandatory, out } }, index) => (
            <ListItem
              key={index}
              secondaryAction={<MandatoryIcon mandatory={mandatory} />}
              sx={{
                bgcolor: index % 2 === 0 ? "#e3f2fd" : "#f5f5f5",
                borderRadius: 2,
                opacity: setOpacity(out, digitalTwinName, digitalTwinVersion),
              }}
            >
              {inValue}
            </ListItem>
          )
        )}
      </List>

      <Typography sx={{ mt: 2 }} variant={isMobile ? "h6" : "h5"} gutterBottom>
        {t("csv_example")}
      </Typography>
      <Paper
        elevation={3}
        sx={{
          textAlign: "center",
          background: "#f0f0f0",
          overflowX: "auto",
          borderRadius: 2,
          width: "100%",
        }}
      >
        <pre
          style={{
            whiteSpace: "pre-wrap",
            wordBreak: "break-word",
            fontSize: isMobile ? "12px" : "16px",
          }}
        >
          label;
          {mappingConfigurations
            ?.map(({ attributes: { in: inValue } }) => inValue)
            .join(";")}
        </pre>
      </Paper>
    </Box>
  );
};

export default GuideMapping;
