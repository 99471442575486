import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv6";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";
import { store } from "../../redux/store";
import HeatMeterForm from "../form/formComponents/HeatMeterForm";
import { setSelectedMeter } from "../../redux/slices/meterSlice";
import { IwdFormAuthWrapper } from "../../utils/IwdFormAuthWrapper";
import { useTranslation } from "react-i18next";
import Point from "./widgets/Poin";
import DigitalTwinImage from "../form/formComponents/DigitalTwinImage";
import SecurityWidget from "../form/formComponents/customWidgets/SecurityWidget";
import MeterTypeWidget from "./widgets/MeterTypeWidget";
import ImpulsiveInput from "../form/formComponents/ImpulsiveInput";
import TagsWidget from "./widgets/TagsWidget";
import TextareWidget from "./widgets/TextareaWidget";
import TimeZoneWidget from "./widgets/TimeZoneWidget";
import ReverseWidget from "./widgets/ReverseWidget";
import NetworkAdapterWidget from "./widgets/NetworkAdapterWidget";
import SelectWidget from "./widgets/SelectWidget";
import DinamicHeatMeterForm from "../form/formComponents/DinamicHeatMeterForm";

const fields = {
  Point,
  DigitalTwinImage,
  SecurityWidget,
  ImpulsiveInput,
  MeterTypeWidget,
  TagsWidget,
  TimeZoneWidget,
  TextareWidget,
  ReverseWidget,
  NetworkAdapterWidget,
  SelectWidget,
};

const generateUpdateFormConfig = (config, t, meterType, fields = {}) => {
  const schema = {
    title: "Update Meter",
    type: "object",
    properties: {},
    required: [],
    errorMessage: {
      properties: {},
    },
  };

  const uiSchema = {
    "ui:order": [],
  };

  Object.entries(config)
    .filter(([, value]) => value.update)
    .sort(([, a], [, b]) => a.order - b.order)
    .forEach(([key, value]) => {
      schema.properties[key] = {
        title: t(key),
        type: value.type,
        ...(value.selectValues && { selectValues: value.selectValues }),
        ...(fields[key] !== undefined ? { default: fields[key] } : {}),
        ...(value.readOnly && { readOnly: value.readOnly }),
        ...(value.properties && { properties: value.properties }),
      };

      if (value.required) {
        schema.required.push(key);
      }

      if (value.errorMessage) {
        schema.errorMessage.properties[key] = value.errorMessage;
      }

      uiSchema[key] = {
        "ui:field": value.widget,
        "ui:options": {
          size: value.sizeUpdate || 6, // Используем sizeUpdate
        },
      };

      if (value.widget === "MeterTypeWidget") {
        uiSchema[key]["ui:options"] = {
          ...uiSchema[key]["ui:options"],
          meterType: meterType,
        };
      }

      uiSchema["ui:order"].push(key);
    });

  return { schema, uiSchema };
};

const objectFieldTemplate = (props) => {
  return (
    <Grid container alignItems="center" rowSpacing={3} spacing={2}>
      {props.properties?.map((element) => {
        const size = props.uiSchema[element.name]?.["ui:options"]?.size || 6;

        return (
          <Grid key={element.id} item xs={size}>
            {element.content}
          </Grid>
        );
      })}
    </Grid>
  );
};

const DinamicMeterUpdate = ({ closeModal, setMeterData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const updateMode = useSelector((state) => state?.meterSlice?.updateMode);
  const [updateMeter, { data: updData }] = store.useUpdateMeterMutation();
  const [heatData, setHeatData] = useState(null);
  const meterId =
    useSelector((state) => state?.meterSlice?.meterShow)?.id ??
    localStorage.getItem("meterID");
  const meterClass =
    useSelector((state) => state?.meterSlice?.meterShow)?.attributes?.class ??
    localStorage?.getItem("meterClass");
  const meterData =
    useSelector((state) => state?.meterSlice?.meterShow)?.attributes?.fields ??
    JSON.parse(localStorage.getItem("meterData"));
  const mappedFields = {
    ...meterData,
    coordinates: meterData?.coordinates?.coordinates,
    security: meterData?.security?.acl,
  };
  const [formData, setFormData] = useState({});
  const [heatValidation, setHeatValidation] = useState("waiting");
  const twinId =
    useSelector((state) => state?.meterSlice?.meterShow)?.attributes?.fields
      ?.digital_twin_id ?? localStorage.getItem("twinId");
  const security = IwdFormAuthWrapper({ section: "security" });

  const selectedDigitalTwin = useSelector((state) =>
    state?.digitalTwinSlice?.digitalTwins?.find((item) => item.id === twinId)
  );

  const meterType = meterData?.type;

  const currentConfig =
    twinId && (window.METER?.[twinId] || window.METER.default);
  const { schema, uiSchema } = currentConfig
    ? generateUpdateFormConfig(currentConfig, t, meterType, mappedFields)
    : { schema: null, uiSchema: null };

  useEffect(() => {
    if (updData) {
      navigate(`/meters/${updData?.data?.id}`);
    }
  }, [updData]);

  useEffect(() => {
    console.log("schemaupd", mappedFields);
  }, [mappedFields]);

  const onSubmitClick = (formData) => {
    const attributes = {
      fields: {
        class: meterClass,
        ...formData,
        digital_twin_id: twinId.toString(),
        security:
          security && formData?.security?.length > 0
            ? {
                acl: [...formData?.security],
                groups: ["/network_adapter_manager_router", "/codec_manager"],
              }
            : {
                acl: [
                  { name: "/admin", scopes: ["Elixir.show", "Elixir.update"] },
                ],
                groups: ["/admin"],
              },
        coordinates: {
          coordinates: [formData?.coordinates?.lng, formData?.coordinates?.lat],
          type: "Point",
        },
      },
    };

    updateMeter({
      attributes: attributes,
      twinId: twinId,
      instanceId: meterId,
    });
  };

  /*  useEffect(() => {
    if (heatValidation === "validated") {
      const attributes = {
        fields: {
          class: selectedDigitalTwin?.attributes?.digital_twin_fields?.class,
          ...formData,
          digital_twin_id: twinId.toString(),
          security:
            security && formData?.security?.length > 0
              ? {
                  acl: [...formData?.security],
                  groups: ["/network_adapter_manager_router", "/codec_manager"],
                }
              : {
                  acl: [
                    {
                      name: "/admin",
                      scopes: ["Elixir.show", "Elixir.update"],
                    },
                  ],
                  groups: ["/admin"],
                },
          coordinates: {
            coordinates: [
              formData?.coordinates?.lng,
              formData?.coordinates?.lat,
            ],
            type: "Point",
          },
        },
      };
      updateMeter({
        attributes: attributes,
        twinId: twinId,
        instanceId: meterData?.id,
      });
    }
  }, [heatValidation]);
 */
  return (
    <Box>
      {schema && (
        <Box>
          {updateMode == "default" && (
            <Form
              schema={schema}
              uiSchema={{
                "ui:ObjectFieldTemplate": objectFieldTemplate,
                "ui:submitButtonOptions": {
                  submitText: t("update"),
                  props: {
                    variant: window?.BUTTON_TYPE,
                    color: window?.theme?.buttonText,
                  },
                },
                ...uiSchema,
              }}
              fields={fields}
              formData={formData}
              onChange={(changeEvent) => {
                debugger;
                setFormData(changeEvent.formData);
              }}
              onSubmit={(values) => onSubmitClick(values.formData)}
              validator={validator}
              showErrorList={false}
            />
          )}
          {updateMode == "hca" && (
            <DinamicHeatMeterForm
              data={mappedFields}
              otherData={mappedFields}
              heatValidation={heatValidation}
              setHeatValidation={setHeatValidation}
              update={true}
              instanceId={meterId}
              twinId={twinId}
              meterClassName={meterData?.class}
              coordinates={meterData?.coordinates}
              meterType={mappedFields?.manufacturer}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DinamicMeterUpdate;
