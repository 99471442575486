import { useDispatch, useSelector } from "react-redux";
import { store } from "../../redux/store";
import { useEffect } from "react";
import GuideDigitalTwin from "./GuideDigitalTwin";
import GuideMapping from "./GuideMapping";
import { Typography, Box, Paper, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const Guide = ({ policyMode = false }) => {
  const currentConfig = useSelector(
    (state) => state?.importSlice?.selectedConfiguration
  );
  const [getConfigurationMapping] = store.useLazyGetMappingConfigurationQuery();
  const isMobile = useMediaQuery("(max-width:600px)");
  const digitalTwinName = useSelector(
    (state) => state?.importSlice?.selectedDtName
  );
  const digitalTwinVersion = useSelector(
    (state) => state?.importSlice?.selectedDtVersion
  );
  useEffect(() => {
    if (currentConfig && !policyMode) {
      getConfigurationMapping(currentConfig.id);
    }
  }, [currentConfig]);

  useEffect(() => {
    if (policyMode) {
      getConfigurationMapping(window.IMPORT_CONFIGURATION_ID);
    }
  }, [policyMode]);

  const { t } = useTranslation();

  return (
    <Box sx={{ margin: "auto" }}>
      {currentConfig || policyMode ? (
        <Box sx={{ p: 1, maxHeight: "70vh", overflowY: "auto" }}>
          {!policyMode && <GuideDigitalTwin />}
          <GuideMapping
            digitalTwinName={digitalTwinName}
            digitalTwinVersion={digitalTwinVersion}
          />
          <Paper
            elevation={3}
            sx={{
              mt: 3,
              ml: 1,
              textAlign: "center",
              width: "97%",
              padding: 1,
              borderRadius: 3,
            }}
          >
            <Typography align="center" variant={isMobile ? "h6" : "h5"}>
              {t("import_basic_rules")}
            </Typography>
            <Typography>{t("import_basic_rules_description")}</Typography>
          </Paper>
        </Box>
      ) : (
        <Typography align="center" variant={isMobile ? "h6" : "h5"}>
          {t("select_one_import_configuration")}
        </Typography>
      )}
    </Box>
  );
};

export default Guide;
