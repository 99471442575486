import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useTranslation } from "react-i18next";

const ReverseWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(false);
  const selectedMeter = useSelector(
    (state) => state?.meterSlice?.selectedMeter
  );
  const meterClass = selectedMeter?.attributes?.digital_twin_fields?.class;

  useEffect(() => {
    props.onChange(value);
  }, [value]);
  const hadnleValue = (e) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    console.log("propsreverse", props);
  }, [props]);

  useEffect(() => {
    if (props?.schema?.default) {
      setValue(props?.schema?.default);
    }
  }, [props?.schema?.default]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">
          {meterClass === "MAD:00:0D"
            ? t("input_mounted")
            : t("mounted_reverse")}
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={
            meterClass === "MAD:00:0D"
              ? t("input_mounted")
              : t("mounted_reverse")
          }
          onChange={hadnleValue}
        >
          <MenuItem value={true}>{t("True")}</MenuItem>
          <MenuItem value={false}>{t("False")}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};
export default ReverseWidget;
