import { useSelector, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import DownloadIcon from "@mui/icons-material/Download";
import { setErrors } from "../redux/slices/errorSlice";
import { setMessage } from "../redux/slices/snackbarSlice";
import { setInviteFileData } from "../redux/slices/inviteSlice";
import { actions } from "../redux/slices/iwdWebSocketSlice";

import { dwCenterActions } from "../redux/slices/dwCenterSllice";
import WsConnector from "./WsConnector";

const DownloadCenter = () => {
  const inviteFileData = useSelector(
    (state) => state?.inviteSlice?.inviteFileData
  );
  const channelName = useSelector(
    (state) => state?.dwCenterSllice?.channelName
  );
  const channelParams = useSelector(
    (state) => state?.dwCenterSllice?.channelParams
  );
  const dataFunction = useSelector(
    (state) => state?.dwCenterSllice?.dataFunction
  );
  const hiden = useSelector((state) => state?.dwCenterSllice?.hiden);
  const eventName = useSelector((state) => state?.dwCenterSllice?.eventName);
  const manualClean = useSelector(
    (state) => state?.dwCenterSllice?.manualClean
  );
  const [allValuesNotNull, setAllValuesNotNull] = useState(false);

  useEffect(() => {
    const hasNull = [
      channelName,
      channelParams,
      dataFunction,
      hiden,
      eventName,
      manualClean,
    ].some((value) => value === null);

    setAllValuesNotNull(!hasNull);
  }, [channelName, channelParams, dataFunction, hiden, eventName, manualClean]);
  const dispatch = useDispatch();
  const bearer = localStorage.getItem("user_token");
  let fileName = "";
  const onDownloadClick = (id) => {
    /*     dispatch(loadingOn()); */

    fetch(`${window.BASE_URL}/api/v1/job/${id}/download`, {
      method: "GET",
      headers: {
        "Content-Type": "application/vnd.api+json",
        Authorization: `Bearer ${bearer}`,
      },
      /*  body: JSON.stringify(prepareBodyWithoutId(params, "plugin")), */
    })
      .then((response) => {
        const contentDisposition = response.headers.get("content-disposition");
        fileName = contentDisposition
          .split(";")
          .find((part) => part.trim().startsWith("filename="))
          .split("=")[1]
          .trim();
        if (!response.ok) {
          throw new Error(
            `Network response was not ok, status: ${response.status}`
          );
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${fileName}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        window.URL.revokeObjectURL(url);
      })
      .then(() => {
        /*   setExportModal(false); */
        /*     dispatch(loadingOff()); */
      })
      .catch((error) => {
        console.error("Error during fetch:", error);

        dispatch(setErrors({ error: "Esportazione non è andata a buon fine" }));
      });
  };

  const DnwBtn = ({ msg }) => {
    const { t } = useTranslation();
    function extractId(input) {
      if (typeof input !== "string") return null;
      const match = input.match(/job:\s*(\d+)/);
      return match ? Number(match[1]) : null;
    }
    const id = extractId(msg?.[0]);
    return (
      <Box>
        <Typography>{msg?.[0]}</Typography>

        <Box display="flex" alignItems="center">
          <Typography>{t("can_download")}</Typography>
          <IconButton onClick={() => onDownloadClick(id)}>
            <DownloadIcon />
          </IconButton>
        </Box>

        <Typography>{t("in_jobs_section")}</Typography>
      </Box>
    );
  };

  const sliceFnSelector = () => {
    switch (dataFunction) {
      case "setInviteFileData":
        return setInviteFileData;
      default:
        return () => {};
    }
  };

  useEffect(() => {
    if (inviteFileData) {
      dispatch(setMessage(<DnwBtn msg={inviteFileData} />));
      dispatch(setInviteFileData(null));
      dispatch(actions.cleanData(eventName));
      dispatch(dwCenterActions.resetWsParams());
      dispatch(actions.leaveChannel(channelName));
      dispatch(actions.disconnect());
    }
  }, [inviteFileData]);

  return (
    <>
      {allValuesNotNull && (
        <WsConnector
          channelName={channelName}
          channelParams={channelParams}
          setDataFunction={sliceFnSelector()}
          hiden={hiden}
          eventName={eventName}
          manualClean={manualClean}
        />
      )}
    </>
  );
};
export default DownloadCenter;
