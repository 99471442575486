import { store } from "../../../redux/store";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TimeZoneWidget = (props) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(null);
  const [getByName, resp, lpi] = store.useLazyGetDataByNameQuery();
  useEffect(() => {
    getByName(["time_zone"]);
  }, []);

  const options = resp?.data?.data?.map((item) => ({
    label: item?.attributes?.name,
    value: item?.attributes?.name,
  }));

  useEffect(() => {
    if (value) {
      props.onChange(value);
    }
  }, [value]);
  useEffect(() => {
    console.log("propstz", props);
  }, [props]);
  useEffect(() => {
    if (props?.schema?.default) {
      setValue(props?.schema?.default);
    }
  }, [props?.schema?.default]);
  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      value={value}
      options={options}
      onChange={(event, newValue) => {
        setValue(newValue?.value);
      }}
      fullWidth
      renderInput={(params) => (
        <TextField
          error={props?.rawErrors?.length > 0}
          {...params}
          label={`${t("time_zone")} *`}
        />
      )}
    />
  );
};

export default TimeZoneWidget;
