import React, { useState, useEffect } from "react";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { store } from "../redux/store";
import { setMessage } from "../redux/slices/snackbarSlice";
import IwdButton from "../utils/IwdButton";

const ChangePasswordPage = ({ userId, closeModal }) => {
  const validation = useSelector((state) => state?.authSlice?.validation);
  const [getValidation] = store.useLazyGetValidationQuery();
  const [changePassword, { isSuccess }] = store.useChangePasswordMutation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  });
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);

  useEffect(() => {
    getValidation();
  }, []);

  const onSubmitClick = () => {
    const attributes = {
      current_password: oldPassword,
      new_password: newPassword,
      password_confirmation: passwordConfirmation,
    };

    changePassword({ userId: userId, attributes: attributes });
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setMessage(t("password_successfully_changed")));
      closeModal();
    }
  }, [isSuccess]);

  const [passwordRules, setPasswordRules] = useState({
    minLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumbers: false,
    hasSpecialChar: false,
  });

  const regex = {
    minLength: validation?.minLength?.value || 0,
    uppercaseLength: validation?.uppercaseLength?.value || 0,
    lowercaseLength: validation?.lowercaseLength?.value || 0,
    numberLength: validation?.numberLength?.value || 0,
    symbolLength: validation?.symbolLength?.value || 0,
  };

  const validatePassword = (password) => {
    setPasswordRules({
      minLength: password.length >= regex.minLength,
      hasUpperCase:
        (password.match(/[A-Z]/g) || []).length >= regex.uppercaseLength,
      hasLowerCase:
        (password.match(/[a-z]/g) || []).length >= regex.lowercaseLength,
      hasNumbers: (password.match(/[0-9]/g) || []).length >= regex.numberLength,
      hasSpecialChar:
        (password.match(/[^A-Za-z0-9]/g) || []).length >= regex.symbolLength,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "oldPassword") setOldPassword(value);
    if (name === "newPassword") {
      setNewPassword(value);
      validatePassword(value);
    }
    if (name === "passwordConfirmation") setPasswordConfirmation(value);
  };

  return (
    <Container component="main" maxWidth="sm">
      <Box component="form" noValidate sx={{ mt: 1 }}>
        <TextField
          fullWidth
          label={t("current_password")}
          name="oldPassword"
          type={showOldPassword ? "text" : "password"}
          value={oldPassword}
          onChange={handleChange}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  {showOldPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label={t("new_password")}
          name="newPassword"
          type={showNewPassword ? "text" : "password"}
          value={newPassword}
          onChange={handleChange}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          fullWidth
          label={t("password_confirmation")}
          name="passwordConfirmation"
          type={showPasswordConfirmation ? "text" : "password"}
          value={passwordConfirmation}
          onChange={handleChange}
          sx={{ mb: 2 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() =>
                    setShowPasswordConfirmation(!showPasswordConfirmation)
                  }
                >
                  {showPasswordConfirmation ? (
                    <VisibilityOff />
                  ) : (
                    <Visibility />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <List sx={{ mb: 2 }}>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  align="center"
                  sx={{
                    color: passwordRules.minLength ? "green" : "red",
                    fontSize: "0.875rem",
                  }}
                >
                  {regex.minLength > 1
                    ? t("validation.minLengthPlural", {
                        count: regex.minLength,
                      })
                    : t("validation.minLength", { count: regex.minLength })}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  align="center"
                  sx={{
                    color: passwordRules.hasUpperCase ? "green" : "red",
                    fontSize: "0.875rem",
                  }}
                >
                  {regex.uppercaseLength > 1
                    ? t("validation.uppercaseLengthPlural", {
                        count: regex.uppercaseLength,
                      })
                    : t("validation.uppercaseLength", {
                        count: regex.uppercaseLength,
                      })}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  align="center"
                  sx={{
                    color: passwordRules.hasLowerCase ? "green" : "red",
                    fontSize: "0.875rem",
                  }}
                >
                  {regex.lowercaseLength > 1
                    ? t("validation.lowercaseLengthPlural", {
                        count: regex.lowercaseLength,
                      })
                    : t("validation.lowercaseLength", {
                        count: regex.lowercaseLength,
                      })}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  align="center"
                  sx={{
                    color: passwordRules.hasNumbers ? "green" : "red",
                    fontSize: "0.875rem",
                  }}
                >
                  {regex.numberLength > 1
                    ? t("validation.numberLengthPlural", {
                        count: regex.numberLength,
                      })
                    : t("validation.numberLength", {
                        count: regex.numberLength,
                      })}
                </Typography>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary={
                <Typography
                  align="center"
                  sx={{
                    color: passwordRules.hasSpecialChar ? "green" : "red",
                    fontSize: "0.875rem",
                  }}
                >
                  {regex.symbolLength > 1
                    ? t("validation.symbolLengthPlural", {
                        count: regex.symbolLength,
                      })
                    : t("validation.symbolLength", {
                        count: regex.symbolLength,
                      })}
                </Typography>
              }
            />
          </ListItem>
          {newPassword.length > 0 && passwordConfirmation.length > 0 && (
            <ListItem>
              <ListItemText
                primary={
                  <Typography
                    align="center"
                    sx={{
                      color:
                        newPassword === passwordConfirmation ? "green" : "red",
                      fontSize: "0.875rem",
                    }}
                  >
                    {newPassword === passwordConfirmation
                      ? t("validation.passwordsMatch")
                      : t("validation.passwordsDoNotMatch")}
                  </Typography>
                }
              />
            </ListItem>
          )}
        </List>
        <IwdButton
          disabled={
            !(
              oldPassword &&
              newPassword &&
              passwordConfirmation &&
              passwordRules.minLength &&
              passwordRules.hasUpperCase &&
              passwordRules.hasLowerCase &&
              passwordRules.hasNumbers &&
              passwordRules.hasSpecialChar &&
              newPassword === passwordConfirmation
            )
          }
          onClick={onSubmitClick}
          name={t("change_password")}
          fullWidth
        />
      </Box>
    </Container>
  );
};

export default ChangePasswordPage;
