import { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

import { store } from "../../redux/store";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import LongNameWrapper from "../../utils/LongNameWrapper";
import { CardActionArea } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { setBreadCrumbs } from "../../redux/slices/breadCrumbMeterSlice";
import { setReadingImportInfo } from "../../redux/slices/meterSlice";
import { actions } from "../../redux/slices/iwdWebSocketSlice";
import {
  setCondominiumImportInfo,
  clearCondominiumImportInfo,
} from "../../redux/slices/condominiumSlice";
import IwdLinearProgress from "./LinearProgress";
import { nameTransform } from "../../utils/utilsFunctions";
import theme from "../../theme";
import { loadingOn, loadingOff } from "../../redux/slices/loadingSlice";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import { v4 as uuidv4 } from "uuid";
import { useUpdateCondominiumUploadMutation } from "../../redux/slices/condominiumApi";
/* import { setUuid } from "../../redux/slices/uploadFileSlice"; */
import WsConnector from "../../utils/WsConnector";
import { useDispatch, useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { Box, Button, Typography, Grid } from "@mui/material";
import ModalDialog from "../meters/ModalDialog";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { setMessage } from "../../redux/slices/snackbarSlice";

const Uploader = () => {
  const bearer = localStorage.getItem("user_token");
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [ready, setReady] = useState(false);
  const [msg, setMsg] = useState("");
  const [stopImport, { isSuccess: stopSuccess }] =
    store.useStopCondominiumImportMutation();
  const [confirmImport, { isSuccess: importSuccess }] =
    store.useUpdateCondominiumUploadMutation();
  const { t, i18n } = useTranslation();
  const [uploadPhase, setUploadPhase] = useState(1);
  const [importData, setImportData] = useState([]);
  const [firstUpload, setFirstUpload] = useState(true);
  const [createdCondominiumId, setCreatedCondominiumId] = useState(null);
  const [connect, setConnect] = useState(false);
  const formData = new FormData();
  const currentLocale = i18n.language;
  const importInfo = useSelector(
    (state) => state?.meterSlice?.readingImportInfo
  );
  const endCommunication = importInfo.findIndex((el) => el?.status === "end");
  const locales = localStorage.getItem("i18nextLng");
  const notValidKey = importInfo.findIndex(
    (el) => el?.status === "key_not_valid"
  );
  const missing_k = importInfo.findIndex(
    (el) => el?.status === "missing_k_value"
  );
  const notValidName = importInfo.findIndex(
    (el) => el?.status === "digital_twin_name_not_valid"
  );
  const invalidSubCondName = importInfo.findIndex(
    (el) => el?.status === "error"
  );
  const invalid_name = importInfo.findIndex(
    (el) => el?.status === "error_name"
  );
  const { isWSConnected, channels, data, eventName } = useSelector(
    (state) => state.iwdWebSocketSlice
  );
  const [getErrors, { isSuccess: errorsSuccess }] =
    store.useLazyGetFileErrorsQuery();
  const [getStatistic, { isSuccess: statisticSuccess }] =
    store.useLazyGetFileStatisticQuery();
  const [operationStatus, setOperationStatus] = useState(null);
  const dispatch = useDispatch();
  const [confirmBlock, setConfirmBlock] = useState(false);
  const [files, setFiles] = useState(null);
  const [fileIsReady, setFileIsReady] = useState(false);
  const [uuid, setUuid] = useState(uuidv4());
  const operationId = sessionStorage.getItem("uuid");
  const appBarIsOpen = useSelector(
    (state) => state?.utilsSlice?.headerAppBarOpen
  );
  const loading = useSelector((state) => state?.loadingSlice?.loading);
  /*   useEffect(() => {
    dispatch(setUuid(uuid));
  }, [uuid]); */
  const isEmpty = Object.entries(formData).length === 1;
  const clearFile = () => {
    setFiles(null);
  };
  const dropzoneStyle = {
    height: "200px",
    border: "1px solid black",
  };
  const customSort = (array) => {
    const order = {
      condominium: 1,
      sub_condominium: 2,
      apartment: 3,
      device: 4,
    };

    const copiedArray = [...array];

    copiedArray.sort((a, b) => {
      const itemA = a.item;
      const itemB = b.item;

      if (order[itemA] < order[itemB]) {
        return -1;
      }
      if (order[itemA] > order[itemB]) {
        return 1;
      }
      return 0;
    });

    return copiedArray;
  };
  useEffect(() => {
    if (importSuccess) {
      /*   navigate(`/condominiums`); */
      dispatch(clearCondominiumImportInfo());
    }
  }, [importSuccess]);
  useEffect(() => {
    dispatch(setBreadCrumbs("Readings/import"));
    return () => {
      dispatch(setBreadCrumbs(null));
    };
  }, []);
  useEffect(() => {
    console.log("idik", currentLocale);
  }, [currentLocale]);
  useEffect(() => {}, [uuid]);
  useEffect(() => {
    if (endCommunication !== -1) {
      dispatch(loadingOff());
      dispatch(actions.cashLean());
      /*  dispatch(actions.cleanData(eventName)); */
    }
  }, [endCommunication]);

  const errorInfo = importInfo?.filter(
    (item) =>
      item?.status == "not_exists" ||
      item?.status == "bad_format" ||
      item?.status == "error" ||
      item?.status == "bad_unit"
  );

  useEffect(() => {
    if (importSuccess && endCommunication !== -1) {
      setFirstUpload(false);
    }
  }, [importSuccess, endCommunication]);
  useEffect(() => {}, [files]);
  const sortedImportInfo = customSort(importInfo);

  useEffect(() => {
    if (stopSuccess) {
      dispatch(setMessage(t("stop_import")));
      setUuid(uuidv4());
    }
  }, [stopSuccess]);

  const uploadFile = () => {
    formData.append("file", files);
    formData.append("operation_id", uuid);
    formData.append("locale", locales);
    fetch(`${window.BASE_URL}/api/v1/plugin/import_messages`, {
      method: "POST",
      body: formData,
      // 👇 Set headers manually for single file upload
      headers: {
        /*  "content-Type": undefined, */
        // "content-length": `${files[0].size}`,
        Authorization: `Bearer ${bearer}`,
      },
    }).catch((err) => console.error(err));
  };

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setFiles(file);
    setConnect(true);
    if (!firstUpload) {
      dispatch(
        actions.joinChannel({
          name: "general_info",
          params: { uuid: uuid },
        })
      );
    }
  };

  const handleSubmit = (files, allFiles) => {
    allFiles.forEach((f) => f.remove());
  };
  useEffect(() => {
    if (files && uuid) {
      formData.append("file", files);
      formData.append("operation_id", uuid);
    }
  }, [files]);

  useEffect(() => {}, [uuid]);

  useEffect(() => {}, [importInfo]);

  const onImportClick = () => {
    dispatch(setReadingImportInfo([]));

    dispatch(loadingOn());
    setMsg("Analisi in corso");
    setModal(true);
    setReady(true);

    uploadFile();
  };

  useEffect(() => {
    setUuid(uuidv4);
  }, []);
  const closeModal = () => {
    setModal(false);
    setFiles(null);
    dispatch(setReadingImportInfo([]));
    dispatch(loadingOff());
  };
  const downloadFile = () => {
    const link = document.createElement("a");
    link.href =
      currentLocale == "it-IT"
        ? `${process.env.PUBLIC_URL}/templates/readings_it.xlsx`
        : `${process.env.PUBLIC_URL}/templates/readings_eng.xlsx`;
    link.download =
      currentLocale == "it-IT"
        ? "Template importazione dei messagi.xlsx"
        : "Template Import Readings.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <Box sx={{ height: 700 }}>
      <Typography variant="h4">{t("import_readings")}</Typography>
      <ModalDialog
        open={modal}
        close={closeModal}
        title={"Info"}
        cancell={false}
      >
        <Card sx={{ height: 600, overflowY: "auto", mt: 4, width: 500 }}>
          <CardActionArea>
            <CardContent>
              <IwdLinearProgress modal={modal} />
              <Grid container>
                {errorInfo?.map((item) => {
                  if (item.status == "bad_format") {
                    return (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.error.main}
                        >{`${t("message_with_serial")} ${
                          item?.serial_number
                        } (${t("class")}: ${item?.class}) ${t(
                          "bad_data_format"
                        )}: ${item?.message_date} [${t("row")} : ${
                          item?.row_index
                        }]`}</Typography>
                      </Grid>
                    );
                  }
                  if (item?.status == "not_exists") {
                    return (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.error.main}
                        >{`${t("meter_with_serial")} ${
                          item?.serial_number
                        } (${t("class")}: ${item?.class})   ${t(
                          "not_exists"
                        )} [${t("row")} : ${item?.row_index}]`}</Typography>
                      </Grid>
                    );
                  }
                  if (item?.status == "error") {
                    return (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.error.main}
                        >{`${t(item?.reason)} [${t("row")} : ${
                          item?.row_index
                        }]`}</Typography>
                      </Grid>
                    );
                  }
                  if (item?.status == "bad_unit") {
                    return (
                      <Grid item xs={12} sx={{ mt: 1 }}>
                        <Typography
                          variant="subtitle2"
                          color={theme.palette.error.main}
                        >{`${t("meter_with_serial")} ${
                          item?.serial_number
                        } (${t("class")}: ${item?.class})   ${t("bad_unit")}: ${
                          item?.unit
                        }  [${t("row")} : ${item?.row_index}]`}</Typography>
                      </Grid>
                    );
                  }
                })}
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
        <Button onClick={closeModal} sx={{ float: "right", mt: 2 }}>
          {t("close")}
        </Button>
      </ModalDialog>
      <Box width={"100%"} sx={{ mt: 2 }}>
        {files == null && (
          <Dropzone
            /*     getUploadParams={getUploadParams} */

            styles={{
              dropzone: {
                height: 20,
                overflow: "hidden",
                width: "100%",
                borderRadius: 5,
              },
            }}
            accept=".xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            maxFiles={1}
            inputContent={t("upload_msg")}
          />
        )}
        {files !== null && (
          <Box
            display={"flex"}
            width={"100%"}
            justifyContent={"center"}
            sx={{ mt: 2, height: 126 }}
          >
            <Card sx={{ width: "100%", height: 126, borderRadius: 2 }}>
              <CardActionArea sx={{ height: "100%" }}>
                <CardContent>
                  <Box display={"flex"} alignContent={"center"}>
                    <Typography variant="subtitle2">
                      {`File to upload : ${files?.name}`}
                    </Typography>
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        )}
        <Box display={"flex"} width={"100%"} justifyContent={"center"}>
          <Box>
            <Button
              sx={{
                mt: 2,
              }}
              variant={window.BUTTON_TYPE}
              disabled={!files}
              onClick={onImportClick}
            >
              {t("import")}
            </Button>
            <Button
              sx={{
                mt: 2,

                ml: 2,
              }}
              variant={window.BUTTON_TYPE}
              disabled={!files}
              onClick={clearFile}
            >
              {t("clear")}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={{ height: 400 }}>
        <Box display={"flex"} sx={{ mt: 2 }}>
          <Box sx={{ width: "100%" }}>
            <Card sx={{ width: "100%" }}>
              <Box sx={{ padding: 2 }}>
                <CardContent>
                  <Button
                    onClick={downloadFile}
                    sx={{ mt: 2, mb: 1, float: "right" }}
                    variant={window.BUTTON_TYPE}
                  >
                    {t("get_template")}
                  </Button>
                  <Typography variant="subtitle1">
                    Istruzioni importazione di letture
                  </Typography>
                  <ol>
                    <li>
                      <Typography variant="subtitle2">
                        Seleziona il file necessario in formato XLSX.
                      </Typography>
                    </li>
                    {/*  <li>
                      <Typography variant="subtitle2">
                        La data deve essere in formato ISO 8601
                      </Typography>
                    </li> */}
                    {/*  <li>
                      <Typography variant="subtitle2">
                        La classe deve essere in formato HEX
                      </Typography>
                    </li> */}
                    {/*  <li>
                      <Typography variant="subtitle2">
                        Unità di misura per i ripartitori deve essere "hca"
                      </Typography>
                    </li> */}

                    {/*  <li>
                      <Typography variant="subtitle2">
                        Unità di misura per i contatori di calore deve essere
                        "Wh" o "kWh"
                      </Typography>
                    </li> */}
                    {/*  <li>
                      <Typography variant="subtitle2">
                        Unità di misura per i contatori d'acqua deve essere
                        "m^3"
                      </Typography>
                    </li> */}
                    <li>
                      <Typography variant="subtitle2">
                        Premi il pulsante "Importa".
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Per ulteriori informazioni, consultare la seconda pagina
                        del template scaricato (Sheet 2).
                      </Typography>
                    </li>
                    <li>
                      <Typography variant="subtitle2">
                        Per le letture dei ripartitori, se il ripartitore ha un
                        valore K impostato, viene calcolato il valore
                        parametrizzato. Altrimenti, il valore parametrizzato
                        sarà uguale al valore non convertito.
                      </Typography>
                    </li>
                  </ol>
                </CardContent>
              </Box>
            </Card>
          </Box>
        </Box>

        {connect && (
          <WsConnector
            channelName={"general_info"}
            channelParams={{ uuid: uuid }}
            setDataFunction={setReadingImportInfo}
            hiden={true}
            eventName={"update"}
            manualClean={true}
            /*  setDataFunction={updateDashboardData} */
          />
        )}
      </Box>
    </Box>
  );
};
export default Uploader;
